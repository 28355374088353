<template>
  <div>
    <b-row class="mt-2" v-if="payment.principalSaleUuid">
      <b-col cols="12">
        <p>
          Esta {{ travel?.is_total ? "reserva" : "venta" }} viene de otro tour
          con esta fecha <strong>{{ travel?.departure_date }}</strong
          >
        </p>
      </b-col>
    </b-row>
    <section v-else>
      <b-row class="mt-2">
        <b-col cols="12">
          <h6>Pagos Recaudados</h6>
        </b-col>
        <b-col cols="12">
          <base-action-table
            :records="payments"
            :fields="fields"
            :busy="isBusy"
            :filtering="filtering"
            :module="module"
            :isVisible="debt > 0 && payment.statusSale == 'Activo'"
            @creating="handleCreating"
            @editing="handleEditing"
            @deleting="handleDeleting"
          >
            <template #cell(payment_price)="data">
              <div
                v-if="data.item.payment_price"
                :class="{
                  'text-danger': priceNegative(data.item.payment_price),
                }"
              >
                {{ formatPrice(data.item.payment_price) }}
              </div>
            </template>
            <template #cell(debt)="data">
              <div v-if="data.item.debt">
                {{ formatPrice(data.item.debt) }}
              </div>
            </template>
          </base-action-table>
        </b-col>
      </b-row>
      <b-row class="mt-2" v-if="devolutions.length > 0">
        <b-col cols="12">
          <h6>Devoluciónes Realizadas</h6>
        </b-col>
        <b-col cols="12">
          <base-action-table
            :records="devolutions"
            :fields="fields2"
            :busy="isBusy"
            :isVisible="false"
            :filtering="filtering"
            :module="module"
            @editing="handleEditing"
          >
            <template #cell(payment_price)="data">
              <div
                v-if="data.item.payment_price"
                :class="{
                  'text-danger': priceNegative(data.item.payment_price),
                }"
              >
                {{ formatPrice(data.item.payment_price) }}
              </div>
            </template>
            <template #cell(debt)="data">
              <div v-if="data.item.debt">
                {{ formatPrice(data.item.debt) }}
              </div>
            </template>
          </base-action-table>
        </b-col>
      </b-row>
      <b-row class="mt-2" v-if="moves.length > 0">
        <b-col cols="12">
          <h6>Pagos de tiquetes movidos a otra empresa</h6>
        </b-col>
        <b-col cols="12">
          <base-action-table
            :records="moves"
            :fields="fields2"
            :busy="isBusy"
            :isVisible="false"
            :filtering="filtering"
            :module="module"
            @editing="handleEditing"
          >
            <template #cell(payment_price)="data">
              <div
                v-if="data.item.payment_price"
                :class="{
                  'text-danger': priceNegative(data.item.payment_price),
                }"
              >
                {{ formatPrice(data.item.payment_price) }}
              </div>
            </template>
            <template #cell(debt)="data">
              <div v-if="data.item.debt">
                {{ formatPrice(data.item.debt) }}
              </div>
            </template>
          </base-action-table>
        </b-col>
      </b-row>
    </section>
    <base-modal :title="title" :id="id">
      <template v-slot:form>
        <form-payment
          :record="record"
          :payment="payment"
          @save="updatePayment"
          :isDisabled="isDisabled"
          :debt="debt"
        />
      </template>
    </base-modal>
  </div>
</template>

<script>
import { BCol, BRow } from "bootstrap-vue";
import BaseActionTable from "@/components/base/BaseActionTable";
import BaseModal from "@/components/base/BaseModal";
import FormPayment from "./FormPayment";
import ActionCRUD from "@/mixins/ActionCRUD";

export default {
  inject: ["paymentRepository"],
  mixins: [ActionCRUD],
  components: {
    BCol,
    BRow,
    BaseActionTable,
    BaseModal,
    FormPayment,
  },
  props: {
    payment: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      record: null,
      payments: [],
      devolutions: [],
      moves: [],
      fields: [
        {
          key: "payment_price",
          label: "Monto recaudado",
          sortable: true,
        },
        {
          key: "payment_method",
          label: "Método de pago",
          sortable: true,
        },
        {
          key: "debt",
          label: "Debe",
          sortable: true,
        },
        { key: "actions", label: "Accion" },
      ],
      fields2: [
        {
          key: "payment_price",
          label: "Monto pagado",
          sortable: true,
        },
        {
          key: "payment_method",
          label: "Método de pago",
          sortable: true,
        },
        { key: "actions", label: "Accion" },
      ],
      isBusy: false,
      filtering: false,
      isDisabled: false,
      id: "abonar-ticket-modal",
      title: "Abonar",
      module: "9999e244-e975-44de-ac57-1a3fbe1a2cba",
      debt: "",
      travel: null,
    };
  },
  mounted() {
    const me = this;
    me.loadPayments();
  },
  methods: {
    async loadPayments() {
      const me = this;
      try {
        me.isBusy = true;
        me.payments = [];
        me.devolutions = [];
        me.moves = [];
        me.debt = "";
        me.travel = null;
        const { data } = await me.paymentRepository.getAll(me.payment.saleUuid);

        if (me.payment.principalSaleUuid) {
          me.travel = {
            uuid: data[0].sale.travel.uuid,
            departure_date: data[0].sale.travel.departure_date,
            is_total: data[0].sale.is_total,
          };
        }
        data.forEach((element) => {
          if (element.type === "s") {
            me.payments.push(element);
          } else if (element.type === "dev") {
            me.devolutions.push(element);
          } else if (element.type === "m") {
            me.moves.push(element);
          }
        });
        me.debt = me.payments.find((element) => element.type === "s").debt;
      } catch (error) {
        const { status } = error.data;
        if (status == 419) {
          me.$bvModal.show("expired-token-model");
        } else if (status == 403) {
          me.$bvModal.show("session-finish-modal");
        }
      } finally {
        me.isBusy = false;
      }
    },
    handleCreating() {
      const me = this;
      me.title = "Abonar";
      me.isDisabled = false;
      me.record = null;
      me.$bvModal.show(me.id);
    },
    handleEditing(item) {
      const me = this;
      me.title = "Actualizar Abono";
      me.isDisabled = true;
      me.record = item;
      me.$bvModal.show(me.id);
    },
    updatePayment() {
      const me = this;
      me.messageSuccess();
      me.loadPayments();
      me.$bvModal.hide(me.id);
      me.$emit("loadSales");
    },
    formatPrice(price) {
      const formatterPeso = new Intl.NumberFormat("es-CO", {
        style: "currency",
        currency: "COP",
        minimumFractionDigits: 0,
      });

      return formatterPeso.format(parseFloat(price));
    },
    priceNegative(value) {
      const isUtilityNegative =
        Math.sign(value) === 1 || Math.sign(value) === 0 ? false : true;

      return isUtilityNegative;
    },
    async handleDeleting(uuid) {
      const me = this;
      me.$bvModal
        .msgBoxConfirm("¿Seguro que deseas eliminar este registro?", {
          title: "Confirmar eliminación",
          size: "sm",
          buttonSize: "sm",
          okVariant: "danger",
          okTitle: "Si",
          cancelTitle: "No",
          footerClass: "p-2",
          hideHeaderClose: false,
          centered: true,
        })
        .then(async (value) => {
          if (value) {
            try {
              await me.paymentRepository.delete(uuid);

              me.messageSuccess(
                "Registro eliminado",
                "Registro eliminado con exito"
              );
              me.loadPayments();
              me.$bvModal.hide(me.id);
              me.$emit("loadSales");
            } catch (error) {
              const { status } = error.data;
              if (status == 419) {
                me.$bvModal.show("expired-token-model");
              } else if (status == 403) {
                me.$bvModal.show("session-finish-modal");
              }

              let title = "Error al eliminar registro";
              let messageErr = "Registro no Eliminado.";

              me.messageError(title, messageErr, "icon-message-error");
            }
          }
        });
    },
  },
};
</script>
