<template>
  <b-table
    class="table-sales"
    ref="selectableTable"
    striped
    hover
    responsive
    bordered
    small
    selectable
    b-table-select-multi
    :fields="fields"
    :items="records"
    selected-variant="warning"
    @row-selected="onRowSelected"
    :tbody-tr-class="rowClass"
  >
    <template #head(selected)>
      <b-form-checkbox v-model="statusAll" @input="selectAllRows" />
    </template>
    <template #cell(selected)="{ rowSelected }">
      <template v-if="rowSelected">
        <div class="d-flex align-items-center justify-content-center">
          <b-icon-check-circle-fill class="icon_check" aria-hidden="true" />
        </div>
      </template>
      <template v-else>
        <span aria-hidden="true">&nbsp;</span>
      </template>
    </template>
    <template #cell(passenger)="data">
      {{
        data.item.chairSaleAccompanist.accompanist.names
          ? `${data.item.chairSaleAccompanist.accompanist.names} ${data.item.chairSaleAccompanist.accompanist.last_names}`
          : "Agregar información"
      }}
      <b-spinner
        v-if="!data.item.chairSaleAccompanist.accompanist.names && data.item.status === 'Activo'"
        class="ml-2"
        small
        type="grow"
        variant="primary"
        label="Loading..."
      ></b-spinner>
    </template>
    <template #cell(identification)="data">
      {{
        data.item.chairSaleAccompanist.accompanist.identification
          ? data.item.chairSaleAccompanist.accompanist.identification
          : "Agregar información"
      }}
      <b-spinner
        v-if="!data.item.chairSaleAccompanist.accompanist.identification && data.item.status === 'Activo'"
        class="ml-2"
        small
        type="grow"
        variant="primary"
        label="Loading..."
      ></b-spinner>
    </template>
    <template #cell(sale_price)="data">
      {{ formatPrice(data.item.sale_price) }}
    </template>
    <template #cell(sale_price_devolution)="data">
      {{ data.item.sale_price_devolution ? formatPrice(data.item.sale_price_devolution) : '' }}
    </template>
    <template #cell(sale_price_move_enterprise)="data">
      {{ data.item.sale_price_move_enterprise ? formatPrice(data.item.sale_price_move_enterprise) : '' }}
    </template>
    <template #cell(typeSale)="data">
      <b-badge
        class="badge-type-sale"
        :class="colorTypeSale(data.item.typeSale)"
      >
        {{ loadTypeSale(data.item.typeSale) }}
      </b-badge>
    </template>
    <template #cell(actions)="data">
      <div v-if="data.item.status === 'Activo'">
        <b-button
          :id="'p-' + data.item.uuid"
          class="btn-icon actions_ margin-right"
          size="sm"
        >
          <b-icon-three-dots-vertical />
        </b-button>
        <popover-table-chair
          :target="'p-' + data.item.uuid"
          :record="data.item"
          :module="module"
          @detail="detailSale"
          @edit="editSale"
          @delete="deleteSale"
          @baby="addBaby"
        />
      </div>
      <div v-else>
       {{ messageTour(data.item) }}
      </div>
    </template>
  </b-table>
</template>

<script>
import PermissionAction from "@/mixins/PermissionAction";
//Components
import {
  BTable,
  BSpinner,
  BButton,
  BBadge,
  BIconThreeDotsVertical,
  BFormCheckbox,
  BIconCheckCircleFill,
} from "bootstrap-vue";
import PopoverTableChair from "./PopoverTableChair";

export default {
  inject: ["saleRepository"],
  mixins: [PermissionAction],
  components: {
    BTable,
    BButton,
    BIconThreeDotsVertical,
    PopoverTableChair,
    BIconCheckCircleFill,
    BFormCheckbox,
    BSpinner,
    BBadge,
  },
  props: {
    records: {
      type: Array,
      default: () => [],
    },
    sale: {
      type: Object,
      default: null,
    },
    uuidSale: {
      type: String,
      default: null,
    },
    selecteds: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      statusAll: false,
      fields: [
        "selected",
        {
          key: "chair.number_chair",
          label: "N° Silla",
          sortable: true,
        },
        {
          key: "typeSale",
          label: "Tipo de venta",
          sortable: true,
        },
        {
          key: "passenger",
          label: "Pasajero",
          sortable: true,
        },
        {
          key: "identification",
          label: "Identificación",
          sortable: true,
        },
        {
          key: "planClient.name",
          label: "Tipo tour",
          sortable: true,
        },
        {
          key: "sale_price",
          label: "Precio venta",
          sortable: true,
        },
        {
          key: "sale_price_devolution",
          label: "Descuento ó Devolución de dinero",
        },
        {
          key: "sale_price_move_enterprise",
          label: "Precio de venta para empresa",
        },
        {
          key: "user.fullname",
          label: "Vendedor",
          sortable: true,
        },
        { key: "actions", label: "Accion" },
      ],
      module: "0cea77ef-98ac-4fc5-980f-bca65e552359",
      selected: [],
      isDisabled: true,
    };
  },
  watch: {
    records() {
      this.statusAll = false;
    },
    selected() {
      const me = this;
      me.$emit("addSelect", {
        saleUuid: me.uuidSale,
        selecteds: me.selected,
        sale: {
          total: me.sale.total,
          payment_method: me.sale.payment_method,
          is_total: me.sale.is_total,
          userUuid: me.sale.userUuid,
          is_deb: me.sale.is_deb,
          status: me.sale.status,
          clientUuid: me.sale.clientUuid,
        },
      });
    },
  },
  computed: {},
  mounted() {
    const me = this;
    if (me.selecteds.length > 0) {
      const selected = me.selecteds.find((el) => el.saleUuid == me.uuidSale);
      if (selected) {
        me.records.forEach((el, index) => {
          selected.selecteds.forEach((x) => {
            if (el.uuid == x.uuid) me.selectThirdRow(index);
          });
        });
      }
    }
  },
  methods: {
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length;
      this.pagination.currentPage = 1;
    },
    selectThirdRow(index) {
      // Rows are indexed from 0, so the third row is index 2
      this.$refs.selectableTable.selectRow(index);
    },
    onRowSelected(items) {
      const me = this;
      me.selected = [];

      me.records.forEach((el, index) => {
        if (el.status == "moveTour" || el.status == "delete" || el.status == "moveTourEnterprise") {
          me.$refs.selectableTable.unselectRow(index);
        }
      });

      items.forEach((el) => {
        if (el.status !== "moveTour" || el.status !== "delete" || el.status !== "moveTourEnterprise") me.selected.push(el);
      });

      if (me.selected.length == me.records.length) me.statusAll = true;

      if (me.selected.length == 0) me.statusAll = false;

      me.selected.length > 0 ? (me.isDisabled = false) : (me.isDisabled = true);
    },
    selectAllRows() {
      const me = this;
      me.statusAll
        ? me.$refs.selectableTable.selectAllRows()
        : me.$refs.selectableTable.clearSelected();

      me.selected.length > 0 ? (me.isDisabled = false) : (me.isDisabled = true);
    },
    rowClass(item, type) {
      if (!item || type !== 'row') return
      if (item.status === 'moveTour') return 'table-move-tour'
      if (item.status === 'moveTourEnterprise') return 'table-move-tour-enterprise'
      if (item.status === 'deleteTicket' || item.status === 'deleteTicketWithDevol') return 'table-move-tour-delete'

    },
    detailSale(item) {
      this.$emit("detailChairSale", item);
    },
    editSale(item) {
      this.$emit("editChairSale", item);
    },
    deleteSale(item) {
      const me = this;
      me.$emit("deleteChairSale", { ...item, is_deb: me.sale.is_deb});
    },
    formatPrice(price) {
      // if (price.split(".")) {
      //   price = price.split(".").join("");
      // }
      const formatterPeso = new Intl.NumberFormat("es-CO", {
        style: "currency",
        currency: "COP",
        minimumFractionDigits: 0,
      });

      return formatterPeso.format(parseFloat(price));
    },
    loadTypeSale(typeSale) {
      let text = "";

      if (typeSale == "1") {
        text = "Tour";
      } else if (typeSale == "2") {
        text = "Solo ida";
      } else if (typeSale == "3") {
        text = "Solo regreso";
      } else {
        text = "";
      }

      return text;
    },
    colorTypeSale(typeSale) {
      let color = "";

      if (typeSale == "1") {
        color = "occupied";
      } else if (typeSale == "2") {
        color = "one-way1";
      } else if (typeSale == "3") {
        color = "just-come-back1";
      }

      return color;
    },
    addBaby(item) {
      this.$emit("addBaby", item);
    },
    messageTour(item) {
      let text = ""
      
      if (item.status === "moveTour") {
        text = `Movido al tour del ${item?.tourMove}`
      } else if (item.status === "moveTourEnterprise") {
        text = `Movido a la empresa ${item?.enterpriseMove }`
      } else {
        text = "Eliminado"
      }

      return text
    }
  },
};
</script>
<style lang="css">
.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
}

.table-sales .table > thead {
  background: var(--third-color) !important;
  color: var(--white-color);
  font-family: var(--fuente-primary);
  font-size: 14px;
  text-align: center;
}

.table-sales .table > tbody {
  color: var(--black-color);
  font-family: var(--fuente-primary);
  font-size: 14px;
}

.table-sales .table > tbody > tr > td:first-child {
  width: 50px !important;
}

.actions_ {
  background: var(--white-color) !important;
  border: 1px solid var(--second-color) !important;
  color: var(--black-color) !important;
  font-weight: bold !important;
}

.icon_check {
  color: var(--fourth-color);
  font-size: 25px;
}

.badge-type-sale {
  display: inline-block;
  padding: 0.25em 0.4em;
  font-size: 15px !important;
  font-weight: 700;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.occupied {
  background-color: var(--fifth-color);
}

.one-way1 {
  background-color: #CA53FC;
}

.just-come-back1 {
  background-color: var(--fourth-color);
}
</style>
