<template>
  <b-form @submit.prevent="saveData" ref="form" autocomplete="off">
    <b-row v-if="isMessageMoved">
      <b-col lg="12" md="12" sm="12" class="mb-3">
        <p v-if="isBooking2" class="text__ mb-0">
          No se pueden mover reservas que aún deben dinero.
        </p>
      </b-col>
    </b-row>
    <template v-else>
      <b-row>
        <b-col lg="12" md="12" sm="12" class="mb-3">
          <p class="text__ mb-2">Si la empresa a donde vas a mover las ventas no esta registrada en la plataforma, da click en este botón.</p>
          <b-button @click.prevent="() => $emit('moveSaleEnterpriseNotExist')">Mover a empresa registrada</b-button>
        </b-col>
      </b-row>
      <b-row>
        <b-col lg="12" md="12" sm="12" class="mb-3">
          <p class="text__ mb-0">Como la empresa a donde vas a mover las ventas no esta registrada en la plataforma, aqui puedes simular el proceso de mover las ventas para que quede guardado.</p>
        </b-col>
      </b-row>
      <b-row class="mb-3">
        <b-col lg="6" md="12" sm="12" class="mb-3">
          <div role="group">
            <label class="label_input">Nombre de la Empresa</label>
            <b-form-input
              size="sm"
              v-model="formData.enterprise"
            />
          </div>
        </b-col>
        <b-col lg="6" md="12" sm="12" class="mb-3">
          <div role="group">
            <label class="label_input">N° de ventas a mover</label>
            <b-form-input
              size="sm"
              disabled
              :value="cuantityChairSale(formData.moveSales)"
            />
          </div>
        </b-col>
        <b-col lg="6" md="12" sm="12" class="mb-3">
          <div role="group">
            <label for="payment_method" class="label_input"
              >Método de pago*</label
            >
            <v-select
              :options="typeOfPayments"
              :reduce="(option) => option.id"
              label="name"
              class="style-chooser"
              placeholder="Seleccione"
              v-model="formData.payment_method"
            >
              <div slot="no-options">Sin opciones</div>
            </v-select>
          </div>
        </b-col>
        <b-col lg="6" md="12" sm="12" class="mb-3">
          <div role="group">
            <label for="sale_price" class="label_input"
              >Precio de venta unitario*</label
            >
            <b-form-input
              id="sale_price"
              size="sm"
              v-model="formData.sale_price"
              @keyup="calculateTotal"
            />
          </div>
        </b-col>
        <b-col lg="6" md="12" sm="12" class="mb-3">
          <div role="group">
            <label for="total" class="label_input">Precio total</label>
            <b-form-input
              id="total"
              size="sm"
              v-model="formData.total"
              disabled
            />
          </div>
        </b-col>
      </b-row>
      <b-row class="footer-modal-customer">
        <b-col cols="12" class="d-flex justify-content-end">
          <b-button
            type="submit"
            size="lg"
            class="btn btn-success w-width"
            :disabled="isBusy || $v.$invalid"
          >
            <b-spinner class="mr-2" v-if="isBusy" small />
            Guardar
          </b-button>
        </b-col>
      </b-row>
    </template>
  </b-form>
</template>

<script>
import {
  BCol,
  BRow,
  BButton,
  BForm,
  BSpinner,
  BFormInput,
} from "bootstrap-vue";
import vSelect from "vue-select";
import { required } from "vuelidate/lib/validators";
import typePayments from "@/data/typePayments.json";
import { formatNumber } from "@/utils";
import MessageMixin from "@/mixins/messageMixin";
export default {
  inject: ["saleRepository"],
  mixins: [MessageMixin],
  components: {
    BCol,
    BRow,
    BButton,
    BForm,
    BFormInput,
    BSpinner,
    vSelect,
  },
  props: {
    moveSales: {
      type: Array,
      default: null,
    },
    isMessageMoved: {
      type: Boolean,
      default: false,
    },
    isBooking2: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    const me = this;
    return {
      isBusy: false,
      formData: {
        moveSales: me.moveSales,
        total: null,
        sale_price: null,
        payment_method: null,
        enterprise: null,
        chairSales: [],
      },
      typeOfPayments: typePayments,
    };
  },
  validations: {
    formData: {
      moveSales: {
        required,
      },
      total: {
        required,
      },
      sale_price: {
        required,
      },
      payment_method: {
        required,
      },
      enterprise: {
        required,
      }
    },
  },
  async mounted() {
    const me = this;
    me.moveSales.forEach((el) => {
      el.selecteds.forEach((x) => {
        me.formData.chairSales.push({
          uuid: x.uuid,
          chairUuid: x.chairUuid,
        });
      });
    });
  },
  methods: {
    async saveData() {
      const me = this;
      me.$bvModal
        .msgBoxConfirm(
          "¿Seguro que deseas realizar el movimiento de las ventas?",
          {
            title: "Confirmar movimiento",
            size: "sm",
            buttonSize: "sm",
            okVariant: "danger",
            okTitle: "Si",
            cancelTitle: "No",
            footerClass: "p-2",
            hideHeaderClose: false,
            centered: true,
          }
        )
        .then(async (value) => {
          if (value) {
            let title, messageErr;
            try {
              me.isBusy = true;
              await me.saleRepository.moveSaleEnterpriseNotExist(me.formData);
              title = "Ventas movidas";
              messageErr = "Ventas movidas con exito.";
              me.$emit(
                "saleUpdate",
                { title, messageErr },
                "move-sale-enterprise-modal"
              );
            } catch (error) {
              const me = this;
              const { status } = error.data;
              if (status == 419) {
                me.$bvModal.show("expired-token-model");
              } else if (status == 403) {
                me.$bvModal.show("session-finish-modal");
              }

              title = "Error al guardar registro";
              messageErr = "Registro no guardado.";
              me.messageError(title, messageErr);
            } finally {
              me.isBusy = false;
            }
          }
        });
    },
    formatPrice(price) {
      const me = this;
      let price2 = price.split(".")
        ? Number(price.split(".").join(""))
        : Number(price);

      const total = me.formData.chairSales.length * price2;

      const formatterPeso = new Intl.NumberFormat("es-CO", {
        minimumFractionDigits: 0,
      });

      return formatterPeso.format(total);
    },
    calculateTotal() {
      const me = this;
      me.formData.sale_price = formatNumber(me.formData.sale_price);
      me.formData.total = me.formatPrice(me.formData.sale_price);
    },
    cuantityChairSale(moveSales) {
      const chairSales = [];

      moveSales.forEach((el) => {
        el.selecteds.forEach((x) => {
          chairSales.push(x);
        });
      });

      return chairSales.length;
    },
  },
};
</script>

<style lang="css">
.box_number_ {
  border: 1px solid;
  padding: 3px;
  font-size: 14px;
  border-radius: 0.2rem;
  color: var(--black-color);
  background: rgb(204, 202, 202);
}

/* .vs__selected {
  background: var(--second-color);
}

.vs__dropdown-option--selected {
  background: var(--primary-color);
  color: var(--white-color);
}

.style-chooser2 .vs__search::placeholder,
.style-chooser2 .vs__dropdown-toggle,
.style-chooser2 .vs__dropdown-menu {
  color: var(--black-color);
  font-size: 0.875rem;
}

.style-chooser2 .vs__search::placeholder {
  color: hite;
}

.style-chooser2 .vs__dropdown-toggle {
  height: 38.24px;
  border: 1px solid rgb(107, 107, 107);
}

.style-chooser2 .vs__clear,
.style-chooser2 .vs__open-indicator {
  fill: rgb(107, 107, 107);
}

.style-chooser2 span {
  color: var(--white-color);
}

.vs__selected svg {
  fill: white;
} */
</style>
