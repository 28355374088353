<template>
  <b-form @submit.prevent="saveTicket" ref="form" autocomplete="off">
    <b-row class="mb-3">
      <b-col
        lg="12"
        md="12"
        sm="12"
        v-if="selectedChairs.length > 0"
        class="mb-2"
      >
        <div class="d-flex flex-wrap">
          <div v-for="(item, index) in selectedChairs" :key="index" class="mb-3 mr-3">
            <b-badge variant="danger p-2">
              Silla N° {{ item.number_chair }}
            </b-badge>
          </div>
        </div>
      </b-col>
      <b-col lg="12" md="12" sm="12" class="text-center mb-3">
        <div class="label_input">Datos de la {{ isBooking ? 'Reserva' : 'Venta'}}</div>
      </b-col>
      <b-col lg="12" md="12" sm="12" class="mb-3">
        <div class="label_input text-white">* Campos requeridos.</div>
      </b-col>
      <b-col lg="12" md="12" sm="12" class="mb-3" v-if="isBooking">
        <div role="group">
          <b-form-checkbox
            v-model="isBooking"
            id="checkbox-1"
            name="checkbox-1"
            size="lg"
            disabled
          >
            <div class="subtitle text-white position-relative">Reserva</div>
          </b-form-checkbox>
        </div>
      </b-col>
      <b-col lg="6" md="12" sm="12" class="mb-3">
        <div role="group">
          <label class="label_input">Tipo de venta*</label>
          <v-select
            :options="typeSales"
            :reduce="(option) => option.id"
            label="name"
            class="style-chooser"
            placeholder="Seleccione"
            v-model="formData.typeSale"
            :disabled="isDisabled || record ? true : false"
          >
            <div slot="no-options">Sin opciones</div>
          </v-select>
        </div>
      </b-col>
      <b-col lg="6" md="12" sm="12" class="mb-3">
        <div role="group">
          <label class="label_input">Tipo de tour*</label>
          <v-select
            :options="planClients"
            :reduce="(option) => option.uuid"
            label="name"
            class="style-chooser"
            placeholder="Seleccione"
            v-model="formData.planClientUuid"
            :disabled="isDisabled || record ? true : false"
          >
            <div slot="no-options">Sin opciones</div>
          </v-select>
        </div>
      </b-col>
      <b-col lg="6" md="12" sm="12" class="mb-3">
        <div role="group">
          <label for="sale_price" class="label_input">Precio de venta unitario*</label>
          <b-form-input
            id="sale_price"
            size="sm"
            v-model="formData.sale_price"
            :disabled="isDisabled || record ? true : false"
            @keyup="calculateTotal"
          />
        </div>
      </b-col>
      <b-col
        lg="6"
        md="12"
        sm="12"
        class="mb-3"
        v-if="formData.is_total || isBooking"
      >
        <div role="group">
          <label for="payment_price" class="label_input">Monto abonar*</label>
          <b-form-input
            id="payment_price"
            size="sm"
            v-model="formData.payment_price"
            @keyup="validatePayment"
            :disabled="isDisabled || record ? true : false"
          />
          <span v-if="isPayment" class="help-block text-danger text_error">
            Abono no puede ser mayor al precio total.
          </span>
        </div>
      </b-col>
      <b-col lg="6" md="12" sm="12" class="mb-3" v-if="!isBooking || (isBooking && formData.payment_price > 0)">
        <div role="group">
          <label for="payment_method" class="label_input"
            >Método de pago*</label
          >
          <v-select
            :options="typeOfPayments"
            :reduce="(option) => option.id"
            label="name"
            class="style-chooser"
            placeholder="Seleccione"
            v-model="formData.payment_method"
            :disabled="isDisabled || record ? true : false"
          >
            <div slot="no-options">Sin opciones</div>
          </v-select>
        </div>
      </b-col>
      <b-col lg="6" md="12" sm="12" class="mb-3" v-if="!record">
        <div role="group">
          <label for="total" class="label_input">Precio total</label>
          <b-form-input
            id="total"
            size="sm"
            v-model="formData.total"
            disabled
          />
        </div>
      </b-col>
    </b-row>
    <b-row class="mb-4">
      <b-col lg="12" md="12" sm="12" class="text-center mt-2 mb-2">
        <div class="label_input">Buscar cliente</div>
      </b-col>
      <b-col lg="6" md="8" sm="12">
        <div role="group">
          <label for="identification" class="label_input"
            >Identificación* (Sin puntos, ni comas)</label
          >
          <div class="d-flex align-items-center">
            <b-form-input
              type="number"
              id="identification"
              size="sm"
              v-model="search"
            />
            <b-button
              type="button"
              class="btn btn-secondary ml-2 w-50"
              :disabled="isBusy2 || isValidateSearch"
              @click.prevent="searchClient"
            >
              <b-spinner class="mr-2" v-if="isBusy2" small />
              Buscar
            </b-button>
          </div>
        </div>
      </b-col>
    </b-row>
    <b-row v-if="isSearch">
      <b-col lg="12" md="12" sm="12" class="text-center mt-2 mb-2">
        <div class="label_input">Datos del cliente</div>
      </b-col>
      <b-col lg="6" md="12" sm="12" class="mb-3">
        <div role="group">
          <label for="names" class="label_input">Nombres*</label>
          <b-form-input
            id="names"
            size="sm"
            v-model="formData.names"
            :disabled="isDisabled"
          />
        </div>
      </b-col>
      <b-col lg="6" md="12" sm="12" class="mb-3">
        <div role="group">
          <label for="last_names" class="label_input">Apellidos*</label>
          <b-form-input
            id="last_names"
            size="sm"
            v-model="formData.last_names"
            :disabled="isDisabled"
          />
        </div>
      </b-col>
      <b-col lg="6" md="12" sm="12" class="mb-3">
        <div role="group">
          <label class="label_input">Tipo de identificación*</label>
          <v-select
            :options="typeIndetificatios"
            label="value"
            class="style-chooser"
            placeholder="Seleccione"
            v-model="formData.type_identification"
            :disabled="isDisabled"
          >
            <div slot="no-options">Sin opciones</div>
          </v-select>
        </div>
      </b-col>
      <b-col lg="6" md="12" sm="12" class="mb-3">
        <div role="group">
          <label for="identification" class="label_input"
            >Identificación*</label
          >
          <b-form-input
            id="identification"
            size="sm"
            v-model="$v.formData.identification.$model"
            @blur="$v.formData.identification.$touch()"
            :disabled="isDisabled"
          />
          <span
            v-if="$v.formData.identification.$error"
            class="help-block text-danger text_error"
          >
            {{ messageValidation($v.formData.identification) }}
          </span>
        </div>
      </b-col>
      <b-col lg="6" md="12" sm="12" class="mb-3">
        <div role="group">
          <label class="label_input">País*</label>
          <v-select
            :options="countries"
            label="name"
            class="style-chooser"
            placeholder="Seleccione"
            v-model="formData.codeCountry"
            :disabled="isDisabled"
            :reduce="option => option.phone_code"
          >
            <div slot="no-options">Sin opciones</div>
          </v-select>
        </div>
      </b-col>
      <b-col lg="6" md="12" sm="12" class="mb-3">
        <div role="group">
          <label for="cellphone" class="label_input">Celular*</label>
          <b-form-input
            id="cellphone"
            size="sm"
            v-model="$v.formData.cellphone.$model"
            @blur="$v.formData.cellphone.$touch()"
            :disabled="isDisabled"
          />
          <span
            v-if="$v.formData.cellphone.$error"
            class="help-block text-danger text_error"
          >
            {{ messageValidation($v.formData.cellphone) }}
          </span>
        </div>
      </b-col>
      <!-- <b-col lg="12" md="12" sm="12" class="mb-3">
        <div role="group">
          <b-form-checkbox
            id="checkbox-1"
            v-model="formData.isAccompanist"
            name="checkbox-1"
            :value="true"
            :unchecked-value="false"
            size="lg"
          >
            <label class="label_input"
              >¿Eres un pasajero?</label
            >
          </b-form-checkbox>
        </div>
      </b-col> -->
    </b-row>
    <!-- <b-row v-if="formData.isAccompanist">
      <b-col lg="6" md="12" sm="12" class="mb-3">
        <div role="group">
          <label for="age" class="label_input">Edad*</label>
          <b-form-input
            id="age"
            size="sm"
            v-model="$v.formData.age.$model"
            @blur="$v.formData.age.$touch()"
            :disabled="isDisabled"
          />
          <span
            v-if="$v.formData.age.$error"
            class="help-block text-danger text_error"
          >
            {{ messageValidation($v.formData.age) }}
          </span>
        </div>
      </b-col>
      <b-col lg="6" md="12" sm="12" class="mb-3">
        <div role="group">
          <label for="eps" class="label_input">EPS*</label>
          <b-form-input
            id="eps"
            size="sm"
            v-model="$v.formData.eps.$model"
            @blur="$v.formData.eps.$touch()"
            :disabled="isDisabled"
          />
          <span
            v-if="$v.formData.eps.$error"
            class="help-block text-danger text_error"
          >
            {{ messageValidation($v.formData.eps) }}
          </span>
        </div>
      </b-col>
      <b-col lg="6" md="12" sm="12" class="mb-3">
        <div role="group">
          <label for="family" class="label_input">Nombre de familiar*</label>
          <b-form-input
            id="family"
            size="sm"
            v-model="$v.formData.family.$model"
            @blur="$v.formData.family.$touch()"
            :disabled="isDisabled"
          />
          <span
            v-if="$v.formData.family.$error"
            class="help-block text-danger text_error"
          >
            {{ messageValidation($v.formData.family) }}
          </span>
        </div>
      </b-col>
      <b-col lg="6" md="12" sm="12" class="mb-3">
        <div role="group">
          <label for="cellphone_family" class="label_input">Celular de familiar*</label>
          <b-form-input
            id="cellphone_family"
            size="sm"
            v-model="$v.formData.cellphone_family.$model"
            @blur="$v.formData.cellphone_family.$touch()"
            :disabled="isDisabled"
          />
          <span
            v-if="$v.formData.cellphone_family.$error"
            class="help-block text-danger text_error"
          >
            {{ messageValidation($v.formData.cellphone_family) }}
          </span>
        </div>
      </b-col>
    </b-row> -->
    <b-row class="footer-modal-customer" v-if="!isDisabled">
      <b-col cols="12" class="d-flex justify-content-end">
        <b-button
          type="submit"
          size="lg"
          class="btn btn-success w-width"
          :disabled="isBusy || $v.$invalid || isPayment"
        >
          <b-spinner class="mr-2" v-if="isBusy" small />
          Guardar
        </b-button>
      </b-col>
    </b-row>
  </b-form>
</template>

<script lang="js">
import {
  BCol,
  BRow,
  BButton,
  BForm,
  BSpinner,
  BFormInput,
  BFormCheckbox,
  BBadge,
} from "bootstrap-vue";
import vSelect from "vue-select";
import ActionCRUD from "@/mixins/ActionCRUD";

import {
  required,
  numeric,
  minLength,
  maxLength,
} from "vuelidate/lib/validators";
import { mapGetters } from "vuex";
import { formatNumber } from "@/utils";
import typePayments from "@/data/typePayments.json";
import codeCountries from "@/data/codeCountries.json";
export default {
  inject: ["saleRepository", "planClientRepository", "clientRepository"],
  components: {
    BCol,
    BRow,
    BButton,
    BForm,
    BFormInput,
    BFormCheckbox,
    BSpinner,
    vSelect,
    BBadge,
  },
  mixins: [ActionCRUD],
  props: {
    chair: {
      type: Object,
      default: null,
    },
    userUuid: {
      type: String,
      default: null,
    },
    record: {
      type: Object,
      default: null,
    },
    travel: {
      type: Object,
      default: null,
    },
    isDisabled: {
      type: Boolean,
      default: false,
    },
    isBooking: {
      type: Boolean,
      default: false,
    },
    selectedChairs: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    const me = this;
    return {
      isBusy: false,
      isBusy2: false,
      formData: {
        sale_price: "",
        payment_method: "",
        chairs: [],
        planClientUuid: null,
        names: "",
        last_names: "",
        type_identification: null,
        identification: "",
        codeCountry: "57",
        cellphone: "",
        travelUuid: me.$route.params.travelUuid,
        is_total: me.isBooking,
        payment_price: "",
        total: null,
        typeSale: "1",
        uuid: "",
        // isAccompanist: false
      },
      repository: "saleRepository",
      typeIndetificatios: [
        "Cédula de ciudadanía",
        "Tarjeta de identidad",
        "Registro civil",
        "Pasaporte",
      ],
      planClients: [],
      typeSales: [
        {
          id: "1",
          name: "Tour",
        },
        {
          id: "2",
          name: "Solo ida",
        },
        {
          id: "3",
          name: "Solo regreso",
        },
      ],
      countries: codeCountries,
      typeOfPayments: typePayments,
      isPayment: false,
      isSearch: false,
      search: "",
    };
  },
  computed: {
    ...mapGetters("user", ["getEnterprise"]),
    isValidateSearch() {
      const me = this;
      let validate = true;

      validate = me.search.length > 1 ? false : true;

      return validate;
    },
  },
  validations() {
    const me = this;
    const defaultValidation = {
      sale_price: {
        required,
      },
      payment_method: {
        required,
      },
      planClientUuid: {
        required,
      },
      names: {
        required,
      },
      last_names: {
        required,
      },
      type_identification: {
        required,
      },
      identification: {
        required,
        numeric,
      },
      codeCountry: {
        required,
      },
      cellphone: {
        required,
        numeric,
        minLength: minLength(10),
        maxLength: maxLength(10),
      },
      // age: {
      //   required,
      // },
      // eps: {
      //   required,
      // },
      typeSale: {
        required,
      },
    };

    return me.isBooking
      ? {
          formData: {
            ...defaultValidation,
            payment_price: {
              required,
            },
          },
        }
      : {
          formData: {
            ...defaultValidation,
          },
        };
  },
  async mounted() {
    const me = this;
    me.formData.total = "";
    await me.loadPlanClients();

    if (me.record) {
      me.formData = { ...me.formData, ...me.record };
    } else {
      me.formData.sale_price = me.travel.price;
      me.formData.total = me.formatPrice(me.travel.price);
    }
  },
  methods: {
    async loadPlanClients() {
      const me = this;
      try {
        const { data } = await me.planClientRepository.getAll();
        data.forEach((el) => {
          me.planClients.push({
            uuid: el.uuid,
            name: el.name,
          });
        });
      } catch (error) {
        const { status } = error.data;
        if (status == 419) {
          me.$bvModal.show("expired-token-model");
        } else if (status == 403) {
          me.$bvModal.show("session-finish-modal");
        }
      }
    },
    async saveTicket() {
      const me = this;
      me.formData.chairs = [];
      me.selectedChairs.forEach((el) => {
        me.formData.chairs.push({ uuid: el.uuid });
      });
      try {
        me.isBusy = true;
        const uuid = null;

        await me.saleRepository.save(uuid, {
          ...me.formData,
        });
        me.$emit("save", "modal-sale-ticket");
      } catch (error) {
        const me = this;
        const { status, message } = error.data;
        if (status == 419) {
          me.$bvModal.show("expired-token-model");
        } else if (status == 403) {
          me.$bvModal.show("session-finish-modal");
        } else {
          me.$emit("chairOccupied", message);
        }
      } finally {
        me.isBusy = false;
      }
    },
    formatPrice(price) {
      const me = this;
      let price2 = null;
      price2 = price.split(".")
        ? Number(price.split(".").join(""))
        : Number(price);

      const total = me.selectedChairs.length * price2;

      const formatterPeso = new Intl.NumberFormat("es-CO", {
        minimumFractionDigits: 0,
      });

      return formatterPeso.format(total);
    },
    calculateTotal() {
      const me = this;
      me.formData.sale_price = formatNumber(me.formData.sale_price);
      me.formData.total = me.formatPrice(me.formData.sale_price);
    },
    validatePayment() {
      const me = this;
      let price2 = null;
      me.formData.payment_price = formatNumber(me.formData.payment_price);
      let total = Number(me.formData.total.split(".").join(""));
      price2 = me.formData.payment_price.split(".")
        ? Number(me.formData.payment_price.split(".").join(""))
        : Number(me.formData.payment_price);

      me.formData.payment_method = price2 > 0 ? "" : "No realizó abono";
      me.isPayment = price2 > total ? true : false;
    },
    async searchClient() {
      const me = this;
      me.isSearch = false;
      const search = me.search.split(".").join("");
      try {
        me.isBusy2 = true;
        const { data } = await me.clientRepository.search(search);

        if (data) {
          me.formData.names = data?.names,
          me.formData.last_names = data?.last_names,
          me.formData.type_identification = data?.type_identification,
          me.formData.cellphone = data?.cellphone,
          me.formData.uuid = data?.uuid,
          me.formData.identification = data?.identification;
          me.formData.codeCountry = data?.codeCountry;
        } else {
          me.formData.names = "",
          me.formData.last_names = "",
          me.formData.type_identification = null,
          me.formData.cellphone = "",
          me.formData.uuid = "",
          me.formData.identification = search;
          me.formData.codeCountry = "57";
        }
        me.isSearch = true;
        me.search = "";
      } catch (error) {
        const { status } = error.data;
        if (status == 419) {
          me.$bvModal.show("expired-token-model");
        } else if (status == 403) {
          me.$bvModal.show("session-finish-modal");
        }
      } finally {
        me.isBusy2 = false;
      }
    },
  },
};
</script>

<style lang="css">
.custom-control-label {
  color: var(--black-color);
  font-family: var(--fuente-primary);
  font-weight: 700;
  font-size: 14px;
}
.custom-control-label .subtitle {
  top: 5px;
  font-size: 16px;
}

.custom-checkbox .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: var(--fifth-color);
  border-color: var(--fifth-color);
}
</style>
