<template>
  <b-form @submit.prevent="updateChairSale" ref="form" autocomplete="off">
    <b-row class="mb-3">
      <b-col lg="12" md="12" sm="12" class="text-center mb-2">
        <div class="label_input">Datos del tour</div>
      </b-col>
      <b-col lg="12" md="12" sm="12">
        <div class="label_input text-white">* Campos requeridos.</div>
      </b-col>
      <b-col lg="6" md="12" sm="12" class="mb-3">
        <div role="group">
          <label class="label_input">Tipo de tour*</label>
          <v-select
            :options="planClients"
            :reduce="(option) => option.uuid"
            label="name"
            class="style-chooser"
            placeholder="Seleccione"
            v-model="formData.planClientUuid"
            :disabled="isDisabled"
          >
            <div slot="no-options">Sin opciones</div>
          </v-select>
        </div>
      </b-col>
    </b-row>
    <b-row v-if="!record.names && !isDisabled" class="mb-4">
      <b-col lg="12" md="12" sm="12" class="text-center mt-2 mb-2">
        <div class="label_input">Buscar pasajero</div>
      </b-col>
      <b-col lg="6" md="8" sm="12">
        <div role="group">
          <label for="identification" class="label_input"
            >Identificación* (Sin puntos, ni comas)</label
          >
          <div class="d-flex align-items-center">
            <b-form-input
              type="number"
              id="identification"
              size="sm"
              v-model="search"
            />
            <b-button
              type="button"
              class="btn btn-secondary ml-2 w-50"
              :disabled="isBusy2 || isValidateSearch"
              @click.prevent="searchAccompanist"
            >
              <b-spinner class="mr-2" v-if="isBusy2" small />
              Buscar
            </b-button>
          </div>
        </div>
      </b-col>
    </b-row>
    <b-row v-if="record.names || isSearch || isDisabled">
      <b-col lg="12" md="12" sm="12" class="text-center mb-2 mt-2">
        <div class="label_input">Datos del pasajero</div>
      </b-col>
      <b-col lg="6" md="12" sm="12" class="mb-3">
        <div role="group">
          <label for="names" class="label_input">Nombres*</label>
          <b-form-input
            id="names"
            size="sm"
            v-model="formData.names"
            :disabled="isDisabled"
          />
        </div>
      </b-col>
      <b-col lg="6" md="12" sm="12" class="mb-3">
        <div role="group">
          <label for="last_names" class="label_input">Apellidos*</label>
          <b-form-input
            id="last_names"
            size="sm"
            v-model="formData.last_names"
            :disabled="isDisabled"
          />
        </div>
      </b-col>
      <b-col lg="6" md="12" sm="12" class="mb-3">
        <div role="group">
          <label class="label_input">Tipo de identificación*</label>
          <v-select
            :options="typeIndetificatios"
            label="value"
            class="style-chooser"
            placeholder="Seleccione"
            v-model="formData.type_identification"
            :disabled="isDisabled"
          >
            <div slot="no-options">Sin opciones</div>
          </v-select>
        </div>
      </b-col>
      <b-col lg="6" md="12" sm="12" class="mb-3">
        <div role="group">
          <label for="identification" class="label_input"
            >Identificación*</label
          >
          <b-form-input
            id="identification"
            size="sm"
            v-model="$v.formData.identification.$model"
            @blur="$v.formData.identification.$touch()"
            :disabled="isDisabled"
          />
          <span
            v-if="$v.formData.identification.$error"
            class="help-block text-danger text_error"
          >
            {{ messageValidation($v.formData.identification) }}
          </span>
        </div>
      </b-col>
      <b-col lg="6" md="12" sm="12" class="mb-3">
        <div role="group">
          <label for="cellphone" class="label_input">Celular*</label>
          <b-form-input
            id="cellphone"
            size="sm"
            v-model="$v.formData.cellphone.$model"
            @blur="$v.formData.cellphone.$touch()"
            :disabled="isDisabled"
          />
          <span
            v-if="$v.formData.cellphone.$error"
            class="help-block text-danger text_error"
          >
            {{ messageValidation($v.formData.cellphone) }}
          </span>
        </div>
      </b-col>
      <b-col lg="6" md="12" sm="12" class="mb-3">
        <div role="group">
          <label for="age" class="label_input">Edad*</label>
          <b-form-input
            id="age"
            size="sm"
            v-model="$v.formData.age.$model"
            @blur="$v.formData.age.$touch()"
            :disabled="isDisabled"
          />
          <span
            v-if="$v.formData.age.$error"
            class="help-block text-danger text_error"
          >
            {{ messageValidation($v.formData.age) }}
          </span>
        </div>
      </b-col>
      <b-col lg="6" md="12" sm="12" class="mb-3">
        <div role="group">
          <label for="eps" class="label_input">EPS*</label>
          <b-form-input
            id="eps"
            size="sm"
            v-model="$v.formData.eps.$model"
            @blur="$v.formData.eps.$touch()"
            :disabled="isDisabled"
          />
          <span
            v-if="$v.formData.eps.$error"
            class="help-block text-danger text_error"
          >
            {{ messageValidation($v.formData.eps) }}
          </span>
        </div>
      </b-col>
      <b-col lg="6" md="12" sm="12" class="mb-3">
        <div role="group">
          <label for="family" class="label_input">Nombre de familiar*</label>
          <b-form-input
            id="family"
            size="sm"
            v-model="$v.formData.family.$model"
            @blur="$v.formData.family.$touch()"
            :disabled="isDisabled"
          />
          <span
            v-if="$v.formData.family.$error"
            class="help-block text-danger text_error"
          >
            {{ messageValidation($v.formData.family) }}
          </span>
        </div>
      </b-col>
      <b-col lg="6" md="12" sm="12" class="mb-3">
        <div role="group">
          <label for="cellphone_family" class="label_input">Celular de familiar*</label>
          <b-form-input
            id="cellphone_family"
            size="sm"
            v-model="$v.formData.cellphone_family.$model"
            @blur="$v.formData.cellphone_family.$touch()"
            :disabled="isDisabled"
          />
          <span
            v-if="$v.formData.cellphone_family.$error"
            class="help-block text-danger text_error"
          >
            {{ messageValidation($v.formData.cellphone_family) }}
          </span>
        </div>
      </b-col>
    </b-row>
    <b-row class="footer-modal-customer" v-if="!isDisabled">
      <b-col cols="12" class="d-flex justify-content-end">
        <b-button
          type="submit"
          size="lg"
          class="btn btn-success w-width"
          :disabled="isBusy || $v.$invalid"
        >
          <b-spinner class="mr-2" v-if="isBusy" small />
          Guardar
        </b-button>
      </b-col>
    </b-row>
  </b-form>
</template>

<script>
import {
  BCol,
  BRow,
  BButton,
  BForm,
  BSpinner,
  BFormInput,
} from "bootstrap-vue";
import vSelect from "vue-select";
import ActionCRUD from "@/mixins/ActionCRUD";
import {
  required,
  numeric,
  minLength,
  maxLength,
} from "vuelidate/lib/validators";
import { mapGetters } from "vuex";

export default {
  inject: ["chairSaleRepository", "planClientRepository", "accompanistRepository"],
  components: {
    BCol,
    BRow,
    BButton,
    BForm,
    BFormInput,
    BSpinner,
    vSelect,
  },
  mixins: [ActionCRUD],
  props: {
    record: {
      type: Object,
      default: null,
    },
    isDisabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    const me = this;
    return {
      isBusy: false,
      isBusy2: false,
      formData: {
        planClientUuid: null,
        names: "",
        last_names: "",
        type_identification: null,
        identification: "",
        cellphone: "",
        age: null,
        eps: null,
        family: null,
        cellphone_family: null,
        accompanistUuid: me.record.uuid,
        travelUuid: me.$route.params.travelUuid,
      },
      typeIndetificatios: [
        "Cédula de ciudadanía",
        "Tarjeta de identidad",
        "Registro civil",
        "Pasaporte",
      ],
      planClients: [],
      isSearch: false,
      search: "",
    };
  },
  computed: {
    ...mapGetters("user", ["getEnterprise"]),
    isValidateSearch() {
      const me = this;
      let validate = true;

      validate = me.search.length > 1 ? false : true;

      return validate;
    },
  },
  validations: {
    formData: {
      planClientUuid: {
        required,
      },
      names: {
        required
      },
      last_names: {
        required
      },
      type_identification: {
        required,
      },
      identification: {
        required,
        numeric,
      },
      cellphone: {
        required,
        numeric,
        minLength: minLength(10),
        maxLength: maxLength(10),
      },
      age: {
        required,
      },
      eps: {
        required,
      },
      family: {
        required,
      },
      cellphone_family: {
        required,
        numeric,
        minLength: minLength(10),
        maxLength: maxLength(10),
      },
    }
  },
  async mounted() {
    const me = this;
    await me.loadPlanClients();

    if (me.record.names) {
      me.formData.planClientUuid =  me.record.planClientUuid;
      me.formData.names =  me.record.names;
      me.formData.last_names =  me.record.last_names;
      me.formData.type_identification =  me.record.type_identification;
      me.formData.identification =  me.record.identification;
      me.formData.cellphone =  me.record.cellphone;
      me.formData.age =  me.record.age;
      me.formData.eps =  me.record.eps;
      me.formData.family =  me.record.family;
      me.formData.cellphone_family =  me.record.cellphone_family;
      me.formData.accompanistUuid =  me.record.uuid;
    } else {
      me.formData.planClientUuid = me.record.planClientUuid;
    }
  },
  methods: {
    async loadPlanClients() {
      const me = this;
      try {
        const { data } = await me.planClientRepository.getAll(
          me.getEnterprise.uuid
        );
        data.forEach((el) => {
          me.planClients.push({
            uuid: el.uuid,
            name: el.name,
          });
        });
      } catch (error) {
        const { status } = error.data;
        if (status == 419) {
          me.$bvModal.show("expired-token-model");
        } else if (status == 403) {
          me.$bvModal.show("session-finish-modal");
        }
      }
    },
    async updateChairSale() {
      const me = this;
      try {
        me.isBusy = true;
        
        await me.chairSaleRepository.update(me.record.uuidChairSale, me.formData);
        me.$emit("updateChairSale");
        me.$bvModal.hide("modal-chair-sale");
      } catch (error) {
        const me = this;
        const { status, message } = error.data;
        if (status == 419) {
          me.$bvModal.show("expired-token-model");
        } else if (status == 403) {
          me.$bvModal.show("session-finish-modal");
        }

        let title = 'Error al guardar registro' 
        let messageErr = 'Registro no guardado.';

        if (message == 'Identification is registered') {
          title = 'Error Identificación';
          messageErr = 'El pasajero ya se encuentra registrado en este tour.';
          me.clearField();
        }

        me.messageError(title, messageErr, "icon-message-error");
      } finally {
        me.isBusy = false;
      }
    },
    formatPrice(price) {
      const formatterPeso = new Intl.NumberFormat("es-CO", {
          style: "currency",
          currency: "COP",
          minimumFractionDigits: 0,
        });

      return formatterPeso.format(parseInt(price));
    },
    async searchAccompanist() {
      const me = this;
      me.isSearch = false;
      const search = me.search.split(".").join("");
      try {
        me.isBusy2 = true;
        const { data } = await me.accompanistRepository.search(search);

        if (data) {
            me.formData.names = data.names;
            me.formData.last_names = data.last_names;
            me.formData.type_identification = data.type_identification;
            me.formData.identification = data.identification;
            me.formData.cellphone = data.cellphone;
            me.formData.age = data.age;
            me.formData.eps = data.eps;
            me.formData.family = data.family;
            me.formData.cellphone_family = data.cellphone_family;
        } else {
          me.formData.names = "";
          me.formData.last_names = "";
          me.formData.type_identification = null;
          me.formData.identification = me.search;
          me.formData.cellphone = "";
          me.formData.age = null;
          me.formData.eps = null;
          me.formData.family = null;
          me.formData.cellphone_family = null;
        }
        me.isSearch = true;
        me.search = "";
      } catch (error) {
        const { status } = error.data;
        if (status == 419) {
          me.$bvModal.show("expired-token-model");
        } else if (status == 403) {
          me.$bvModal.show("session-finish-modal");
        }
      } finally {
        me.isBusy2 = false;
      }
    },
    clearField() {
      const me = this;
      me.isSearch = false;
      me.formData.names = "";
      me.formData.last_names = "";
      me.formData.type_identification = null;
      me.formData.identification = "";
      me.formData.cellphone = "";
      me.formData.age = null;
      me.formData.eps = null;
      me.formData.family = null;
      me.formData.cellphone_family = null;
    }
  },
};
</script>

<style>
.custom-control-label {
  color: var(--black-color);
  font-family: var(--fuente-primary);
  font-weight: 700;
  font-size: 14px;
}
</style>
