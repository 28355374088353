var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.payment.principalSaleUuid)?_c('b-row',{staticClass:"mt-2"},[_c('b-col',{attrs:{"cols":"12"}},[_c('p',[_vm._v(" Esta "+_vm._s(_vm.travel?.is_total ? "reserva" : "venta")+" viene de otro tour con esta fecha "),_c('strong',[_vm._v(_vm._s(_vm.travel?.departure_date))])])])],1):_c('section',[_c('b-row',{staticClass:"mt-2"},[_c('b-col',{attrs:{"cols":"12"}},[_c('h6',[_vm._v("Pagos Recaudados")])]),_c('b-col',{attrs:{"cols":"12"}},[_c('base-action-table',{attrs:{"records":_vm.payments,"fields":_vm.fields,"busy":_vm.isBusy,"filtering":_vm.filtering,"module":_vm.module,"isVisible":_vm.debt > 0 && _vm.payment.statusSale == 'Activo'},on:{"creating":_vm.handleCreating,"editing":_vm.handleEditing,"deleting":_vm.handleDeleting},scopedSlots:_vm._u([{key:"cell(payment_price)",fn:function(data){return [(data.item.payment_price)?_c('div',{class:{
                'text-danger': _vm.priceNegative(data.item.payment_price),
              }},[_vm._v(" "+_vm._s(_vm.formatPrice(data.item.payment_price))+" ")]):_vm._e()]}},{key:"cell(debt)",fn:function(data){return [(data.item.debt)?_c('div',[_vm._v(" "+_vm._s(_vm.formatPrice(data.item.debt))+" ")]):_vm._e()]}}])})],1)],1),(_vm.devolutions.length > 0)?_c('b-row',{staticClass:"mt-2"},[_c('b-col',{attrs:{"cols":"12"}},[_c('h6',[_vm._v("Devoluciónes Realizadas")])]),_c('b-col',{attrs:{"cols":"12"}},[_c('base-action-table',{attrs:{"records":_vm.devolutions,"fields":_vm.fields2,"busy":_vm.isBusy,"isVisible":false,"filtering":_vm.filtering,"module":_vm.module},on:{"editing":_vm.handleEditing},scopedSlots:_vm._u([{key:"cell(payment_price)",fn:function(data){return [(data.item.payment_price)?_c('div',{class:{
                'text-danger': _vm.priceNegative(data.item.payment_price),
              }},[_vm._v(" "+_vm._s(_vm.formatPrice(data.item.payment_price))+" ")]):_vm._e()]}},{key:"cell(debt)",fn:function(data){return [(data.item.debt)?_c('div',[_vm._v(" "+_vm._s(_vm.formatPrice(data.item.debt))+" ")]):_vm._e()]}}],null,false,4152863729)})],1)],1):_vm._e(),(_vm.moves.length > 0)?_c('b-row',{staticClass:"mt-2"},[_c('b-col',{attrs:{"cols":"12"}},[_c('h6',[_vm._v("Pagos de tiquetes movidos a otra empresa")])]),_c('b-col',{attrs:{"cols":"12"}},[_c('base-action-table',{attrs:{"records":_vm.moves,"fields":_vm.fields2,"busy":_vm.isBusy,"isVisible":false,"filtering":_vm.filtering,"module":_vm.module},on:{"editing":_vm.handleEditing},scopedSlots:_vm._u([{key:"cell(payment_price)",fn:function(data){return [(data.item.payment_price)?_c('div',{class:{
                'text-danger': _vm.priceNegative(data.item.payment_price),
              }},[_vm._v(" "+_vm._s(_vm.formatPrice(data.item.payment_price))+" ")]):_vm._e()]}},{key:"cell(debt)",fn:function(data){return [(data.item.debt)?_c('div',[_vm._v(" "+_vm._s(_vm.formatPrice(data.item.debt))+" ")]):_vm._e()]}}],null,false,4152863729)})],1)],1):_vm._e()],1),_c('base-modal',{attrs:{"title":_vm.title,"id":_vm.id},scopedSlots:_vm._u([{key:"form",fn:function(){return [_c('form-payment',{attrs:{"record":_vm.record,"payment":_vm.payment,"isDisabled":_vm.isDisabled,"debt":_vm.debt},on:{"save":_vm.updatePayment}})]},proxy:true}])})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }