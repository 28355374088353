<template>
  <b-form @submit.prevent="saveData" ref="form" autocomplete="off">
    <b-row class="mb-3">
      <b-col lg="12" md="12" sm="12">
        <div class="label_input text-white">* Campos requeridos.</div>
      </b-col>
      <b-col lg="12" md="12" sm="12">
        <div class="text_debt text-white mb-2">Debe: {{ format(debt) }}</div>
      </b-col>
      <b-col
        lg="12"
        md="12"
        sm="12"
        v-if="record?.payment_method == 'No realizó abono'"
      >
        <label>No hay campos para actualizar</label>
      </b-col>
      <b-col lg="6" md="12" sm="12" class="mb-3" v-else>
        <div role="group">
          <label for="payment_method" class="label_input"
            >Método de pago*</label
          >
          <v-select
            :options="typeOfPayments"
            :reduce="(option) => option.id"
            label="name"
            class="style-chooser"
            placeholder="Seleccione"
            v-model="formData.payment_method"
          >
            <div slot="no-options">Sin opciones</div>
          </v-select>
        </div>
      </b-col>
      <b-col lg="6" md="12" sm="12" class="mb-3" v-if="!record">
        <div role="group">
          <label for="payment_price" class="label_input">Valor abonar*</label>
          <b-form-input
            id="payment_price"
            size="sm"
            placeholder="Valor abonar"
            v-model="formData.payment_price"
            :disabled="isDisabled"
            @keyup="formatPrice"
          />
          <span v-if="isPayment" class="help-block text-white text_error">
            El valor no debe ser mayor al saldo pendiente.
          </span>
        </div>
      </b-col>
    </b-row>
    <b-row class="footer-modal-customer mb-3">
      <b-col cols="12" class="d-flex justify-content-end">
        <b-button
          type="submit"
          size="lg"
          class="btn btn-success w-width"
          :disabled="
            isBusy ||
            $v.$invalid ||
            isPayment ||
            record?.payment_method == 'No realizó abono'
          "
        >
          <b-spinner class="mr-2" v-if="isBusy" small />
          Guardar
        </b-button>
      </b-col>
    </b-row>
  </b-form>
</template>

<script lang="js">
import {
  BCol,
  BRow,
  BButton,
  BForm,
  BSpinner,
  BFormInput,
} from "bootstrap-vue";
import vSelect from "vue-select";
import { required } from "vuelidate/lib/validators";
import FormatMixin from "@/mixins/FormatMixin"
import messageMixin from "@/mixins/messageMixin";
export default {
  inject: ["paymentRepository"],
  mixins: [FormatMixin, messageMixin],
  components: {
    BCol,
    BRow,
    BButton,
    BForm,
    BFormInput,
    BSpinner,
    vSelect,
  },
  props: {
    record: {
      type: Object,
      default: null,
    },
    isDisabled: {
      type: Boolean,
      default: false,
    },
    payment: {
      type: Object,
      default: null,
    },
    debt: {
      type: String,
      default: '',
    },
  },
  data() {
    const me = this;
    return {
      isBusy: false,
      isPayment: false,
      formData: {
        payment_method: '',
        payment_price: '',
        saleUuid: me.payment.saleUuid,
        principalSaleUuid: me.payment.principalSaleUuid,
        saleUuidSecond: me.payment.saleUuidSecond,
      },
      typeOfPayments: [
        {
          id: "Efectivo",
          name: "Efectivo",
        },
        {
          id: "Bancolombia",
          name: "Bancolombia",
        },
        {
          id: "Bancolombia ahorro a la mano",
          name: "Bancolombia ahorro a la mano",
        },
        {
          id: "Daviplata",
          name: "Daviplata",
        },
        {
          id: "Nequi",
          name: "Nequi",
        },
        {
          id: "Banco Davivienda",
          name: "Banco Davivienda",
        },
        {
          id: "Banco Agrario",
          name: "Banco Agrario",
        },
        {
          id: "Banco AV Villas",
          name: "Banco AV Villas",
        },
        {
          id: "Banco BBVA Colombia S.A",
          name: "Banco BBVA C olombia S.A",
        },
        {
          id: "Banco de Bogota",
          name: "Banco de Bogota",
        },
        {
          id: "Banco de Occidente",
          name: "Banco de Occidente",
        },
        {
          id: "Banco Popular",
          name: "Banco Popular",
        },
      ],
    };
  },
  validations: {
    formData: {
      payment_method: {
        required,
      },
      payment_price: {
        required,
      },
    },
  },
  async mounted() {
    const me = this;
    if (me.record) {
      me.formData = {
        payment_method: me.record.payment_method,
        payment_price: me.record.payment_price,
        saleUuid: me.record.saleUuid,
        uuid: me.record.uuid
      };
      me.formatPrice();
    }
  },
  methods: {
    async saveData() {
      const me = this
      try {
        me.isBusy = true;
        const uuid = me.formData.uuid ? me.formData.uuid : null;
        const option = me.formData.uuid ? 'update' : 'save';

        await me.paymentRepository[option](uuid, { ...me.formData, payment_price: Number(me.formData.payment_price?.split("$")[1].split(".").join("").trim()) });
        me.$emit('save');
      } catch (error) {
        const { status } = error.data;
        if (status == 419) {
          me.$bvModal.show("expired-token-model");
        } else if (status == 403) {
          me.$bvModal.show("session-finish-modal");
        }
        let title = 'Error al guardar registro'
        let messageErr = 'Registro no guardado.';

        const { message } = error.data;
        if (message == 'Payment completed') {
          title = 'Pago completado';
          messageErr = 'Ya completaste el pago total del ticket.'
        } else if (message == "You sent more money") {
          messageErr = 'Estas abonando más dinero de lo que debes.'
        }

        me.messageError(title, messageErr);
      } finally {
        me.isBusy = false;
      }
    },
    formatPrice() {
      const me = this;
      me.formData.payment_price = me.format(me.formData.payment_price);
      me.validatePayment();
    },
    validatePayment() {
      const me = this;
      const price = Number(me.formData.payment_price.split("$")[1].split(".").join("").trim());
      me.isPayment = price > Number(me.debt) ? true : false;
    },
  },
};
</script>

<style lang="css">
.text_debt {
  font-size: 18px;
  font-weight: bold;
}
</style>
