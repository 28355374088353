<template>
  <b-form @submit.prevent="updateTicket" ref="form" autocomplete="off">
    <b-row v-if="isNotEdit">
      <b-col lg="12" md="12" sm="12" class="mb-3">
        <p class="text__ mb-0">
          No se pueden actualizar ventas que vienen de otra empresa.
        </p>
      </b-col>
    </b-row>
    <b-row>
      <b-col lg="12" md="12" sm="12" class="text-center mb-2 mt-2">
        <div class="label_input">Datos del cliente</div>
      </b-col>
      <b-col lg="6" md="12" sm="12" class="mb-3">
        <div role="group">
          <label for="names" class="label_input">Nombres*</label>
          <b-form-input
            id="names"
            size="sm"
            v-model="formData.names"
            :disabled="isDisabled"
          />
        </div>
      </b-col>
      <b-col lg="6" md="12" sm="12" class="mb-3">
        <div role="group">
          <label for="last_names" class="label_input">Apellidos*</label>
          <b-form-input
            id="last_names"
            size="sm"
            v-model="formData.last_names"
            :disabled="isDisabled"
          />
        </div>
      </b-col>
      <b-col lg="6" md="12" sm="12" class="mb-3">
        <div role="group">
          <label class="label_input">Tipo de identificación*</label>
          <v-select
            :options="typeIndetificatios"
            label="value"
            class="style-chooser"
            placeholder="Seleccione"
            v-model="formData.type_identification"
            :disabled="isDisabled"
          >
            <div slot="no-options">Sin opciones</div>
          </v-select>
        </div>
      </b-col>
      <b-col lg="6" md="12" sm="12" class="mb-3">
        <div role="group">
          <label for="identification" class="label_input"
            >Identificación*</label
          >
          <b-form-input
            id="identification"
            size="sm"
            v-model="$v.formData.identification.$model"
            @blur="$v.formData.identification.$touch()"
            :disabled="isDisabled"
          />
          <span
            v-if="$v.formData.identification.$error"
            class="help-block text-danger text_error"
          >
            {{ messageValidation($v.formData.identification) }}
          </span>
        </div>
      </b-col>
      <b-col lg="6" md="12" sm="12" class="mb-3">
        <div role="group">
          <label class="label_input">País*</label>
          <v-select
            :options="countries"
            label="name"
            class="style-chooser"
            placeholder="Seleccione"
            v-model="formData.codeCountry"
            :disabled="isDisabled"
            :reduce="option => option.phone_code"
          >
            <div slot="no-options">Sin opciones</div>
          </v-select>
        </div>
      </b-col>
      <b-col lg="6" md="12" sm="12" class="mb-3">
        <div role="group">
          <label for="cellphone" class="label_input">Celular*</label>
          <b-form-input
            id="cellphone"
            size="sm"
            v-model="$v.formData.cellphone.$model"
            @blur="$v.formData.cellphone.$touch()"
            :disabled="isDisabled"
          />
          <span
            v-if="$v.formData.cellphone.$error"
            class="help-block text-danger text_error"
          >
            {{ messageValidation($v.formData.cellphone) }}
          </span>
        </div>
      </b-col>
    </b-row>
    <b-row class="footer-modal-customer" v-if="!isDisabled">
      <b-col cols="12" class="d-flex justify-content-end mt-2">
        <b-button
          type="submit"
          size="lg"
          class="btn btn-success w-width"
          :disabled="isBusy || $v.$invalid"
        >
          <b-spinner class="mr-2" v-if="isBusy" small />
          Guardar
        </b-button>
      </b-col>
    </b-row>
  </b-form>
</template>

<script>
import {
  BCol,
  BRow,
  BButton,
  BForm,
  BSpinner,
  BFormInput,
} from "bootstrap-vue";
import vSelect from "vue-select";
import ActionCRUD from "@/mixins/ActionCRUD";
import codeCountries from "@/data/codeCountries.json";

import {
  required,
  numeric,
  minLength,
  maxLength,
} from "vuelidate/lib/validators";

export default {
  inject: ["clientRepository"],
  components: {
    BCol,
    BRow,
    BButton,
    BForm,
    BFormInput,
    BSpinner,
    vSelect,
  },
  mixins: [ActionCRUD],
  props: {
    record: {
      type: Object,
      default: null,
    },
    isDisabled: {
      type: Boolean,
      default: false,
    },
    isEditReserva: {
      type: Boolean,
      default: false,
    },
    isNotEdit: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isBusy: false,
      formData: {
        names: "",
        last_names: "",
        type_identification: null,
        identification: "",
        cellphone: "",
        codeCountry: "",
      },
      countries: codeCountries,
      repository: "clientRepository",
      typeIndetificatios: [
        "Cédula de ciudadanía",
        "Tarjeta de identidad",
        "Registro civil",
        "Pasaporte",
      ],
    }
  },
  validations() {
    return {
      formData: {
        names: {
          required,
        },
        last_names: {
          required,
        },
        type_identification: {
          required,
        },
        identification: {
          required,
          numeric,
        },
        codeCountry: {
          required,
        },
        cellphone: {
          required,
          numeric,
          minLength: minLength(10),
          maxLength: maxLength(10),
        },
      }
    }
  },
  methods: {
    async updateTicket() {
      const me = this;
      try {
        me.isBusy = true;

        await me.clientRepository.update(me.formData.uuid, me.formData);
        me.$emit("save", "modal-edit-detail-ticket");
      } catch (error) {
        const me = this;
        const { status, message } = error.data;

        if (status == 419) {
          me.$bvModal.show("expired-token-model");
        } else if (status == 403) {
          me.$bvModal.show("session-finish-modal");
        } else {
          me.$emit("chairOccupied", message);
        }
      } finally {
        me.isBusy = false;
      }
    },
  },
  mounted() {
    const me = this;
    if (me.record) {
      me.formData = { 
        names: me.record.names,
        last_names: me.record.last_names,
        type_identification: me.record.type_identification,
        identification: me.record.identification,
        cellphone: me.record.cellphone,
        codeCountry: me.record.codeCountry,
        uuid: me.record.clientUuid
       };
    }
  },
}
</script>