<template>
  <div v-if="travel">
    <div class="title text-center mb-3">{{ travel.transportation.typeTransportation.name }} {{ travel.transportation.name }}</div>
    <div class="d-flex justify-content-center flex-column">
      <div class="d-flex align-items-center mb-1">
        <div class="box available" />
        <div class="title_box">({{ chairs.available }}) Disponible</div>
      </div>
      <div class="d-flex align-items-center mb-1">
        <div class="box occupied" />
        <div class="title_box">({{ chairs.occupied }}) Tour</div>
      </div>
    </div>
    <div class="d-flex justify-content-center flex-column">
      <div class="d-flex align-items-center mb-1">
        <div class="box round-trip" />
        <div class="title_box">({{ chairs.roundTrip }}) Ida & regreso</div>
      </div>
      <div class="d-flex align-items-center mb-1">
        <div class="box one-way" />
        <div class="title_box">({{ chairs.oneWay }}) Solo ida</div>
      </div>
    </div>
    <div class="d-flex justify-content-center flex-column">
      <div class="d-flex align-items-center mb-1">
        <div class="box just-came-back" />
        <div class="title_box">({{ chairs.justCameBack }}) Solo regreso</div>
      </div>
    </div>
    <b-row>
      <b-col lg="12" md="12" sm="12">
        <div v-if="isNumberSales" class="d-flex justify-content-center">
          <div class="box__ d-flex justify-content-center">
            <div class="text text-center">
              Has completado el número de ventas permitidas ({{ getUser.number_sales }}).
            </div>
          </div>
        </div>
        <div class="d-flex justify-content-center" v-else>
          <div class="board">
            <div v-if="rows.length > 0">
              <div
                v-for="(item, index) in rows"
                :key="index"
                class="list_chairs"
              >
                <div class="d-flex justify-content-center align-items-center">
                  <b-button
                    v-for="chair in item.chair"
                    :key="chair.uuid"
                    class="btn-icon __chair available margin_right"
                    :class="backgroundColor(chair)"
                    :id="'chair' + chair.uuid"
                    :disabled="chair.occupancyStatus || (chair.transport1 && chair.transport2)"
                    size="sm"
                    @click="seletedChair(chair)"
                  >
                    {{ chair.number_chair }}
                  </b-button>
                </div>
              </div>
              <div class="d-flex justify-content-center flex-column mt-3">
                <b-button
                  class="btn_sale background-sale mb-2"
                  size="sm"
                  :disabled="selectedChairs2.length == 0"
                  @click.prevent="saleChairs(false)"
                >
                  Vender
                </b-button>
                <b-button
                  class="btn_sale background-reservation"
                  size="sm"
                  :disabled="selectedChairs2.length == 0"
                  @click.prevent="saleChairs(true)"
                >
                  Reservar
                </b-button>
              </div>
            </div>
            <div v-else class="list_chairs text-center">
              <div class="subtitle">Agrega sillas a tu {{ travel.transportation.typeTransportation.name }}.</div>
            </div>
          </div>
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BCol,
  BRow,
  BButton,
} from "bootstrap-vue";
import { mapGetters } from "vuex";

export default {
  components: {
    BCol,
    BRow,
    BButton,
  },
  props: {
    travel: {
      type: Object,
      default: null,
    },
    rows: {
      type: Array,
      default: () => [],
    },
    chairs: {
      type: Object,
      default: null,
    },
    isTourRealized: {
      type: Boolean,
      default: false,
    },
    selectedChairs: {
      type: Array,
      default: () => [],
    },
    isOccupied: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    const me = this;
    return {
      selectedChairs2: me.selectedChairs,
    };
  },
  watch: {
    selectedChairs(newData) {
      const me = this;
      me.selectedChairs2 = newData;
    },
    isOccupied() {
      const me = this;
      if (me.isOccupied) {
        const btns = document.querySelectorAll(".selected_chair");
        btns.forEach((el) => {
          el.classList.remove("selected_chair");
        });
      }
    }
  },
  computed: {
    ...mapGetters("user", ["getUser"]),
    isNumberSales() {
      const me = this;
      let isSales = false;
      const { number_sales } = me.getUser;
      if (number_sales) {
        let total = me.chairs.occupied + me.chairs.roundTrip + me.chairs.oneWay;
        isSales = total >= Number(number_sales) ? true : false;
      }
      
      return isSales;
    },
  },
  methods: {
    seletedChair(data) {
      const me = this;
      let isActive = true;
      const { number_sales } = me.getUser;

      if (number_sales) {
        isActive = Number(number_sales) == me.selectedChairs2.length ? false : true;
      }

      if (isActive) {
        const chairSelected = document.getElementById(`chair${data.uuid}`);
        const chair = me.selectedChairs2.find((el) => el.uuid == data.uuid);

        if (chair) {
          const index = me.selectedChairs2.findIndex((el) => el.uuid == data.uuid);
          chairSelected.classList.remove("selected_chair");
          me.selectedChairs2.splice(index, 1);
        } else {
          chairSelected.classList.add("selected_chair");
          me.selectedChairs2.push(data);
        }
      }
      
    },
    saleChairs(type) {
      const me = this;
      me.$emit('saleChairs', me.selectedChairs2, type);
    },
    backgroundColor(chair) {
      let color = "";
      
      if (chair.occupancyStatus) {
        color = "occupied";
      } else if (chair.transport1 && chair.transport2) {
        color = "round-trip";
      } else if (chair.transport1) {
        color = "one-way";
      } else if (chair.transport2) {
        color = "just-came-back";
      } else {
        color = "available";
      }
      return color;
    },
  },
}
</script>

<style lang="css">
  .margin_right {
    margin-right: 3.5px;
  }

  .btn_sale {
    border-radius: .5rem;
    font-size: 1.2rem !important;
    height: 3rem;
  }

  .subtitle {
    color: var(--second-color);
    font-family: var(--fuente-econde-sans);
    font-weight: 700;
    font-size: 14px;
  }

  .line__space {
    margin-top: 8px;
    border: 2px solid var(--gray-light-color);
    width: 100%;
  }

  .board {
    border: 4px solid #b85f85 !important;
    border-top-right-radius: 20%;
    border-top-left-radius: 20%;
    width: auto;
    height: auto;
    padding: 25px;
  }

  .list_chairs {
    /* position: relative;
    top: 90px; */
    padding: 5px;
  }

  .__chair {
    background: var(--white-color);
    border: 1px solid var(--primary-color);
    color: var(--black-color);
    font-weight: bold;
    width: 50px;
    height: 50px;
    font-size: 18px !important;
  }

  .__chair:disabled {
    color: var(--white-color);
  }

  .__chair:hover {
    color: var(--white-color) !important;
  }

  .box {
    padding: 10px;
    margin-right: 5px;
  }

  .box__ {
    padding: 10px;
    border: 2px solid;
    width: 50%;
  }

  .box__ .text {
    font-size: 18px;
    color: var(--black-color);
    font-family: var(--fuente-primary);
    font-weight: 700;
  }

  .available {
    background-color: var(--gray-light-color) !important;
    border: none !important;
  }

  .occupied {
    background-color: var(--fifth-color) !important;
    border: none !important;
  }

  .one-way {
    background: linear-gradient(to right, #CA53FC 0%, #CA53FC 50%, var(--gray-light-color) 50%, var(--gray-light-color) 100%) !important;
    border: none !important;
  }
  .just-came-back {
    background: linear-gradient(to right, var(--gray-light-color) 0%, var(--gray-light-color) 50%, var(--fourth-color) 50%, var(--fourth-color) 100%) !important;
    border: none !important;
  }

  .round-trip {
    background: linear-gradient(to right, #CA53FC 0%, #CA53FC 50%, var(--fourth-color) 50%, var(--fourth-color) 100%) !important;
    border: none !important;
  }

  .reserved {
    background-color: var(--second-color);
    border: 2px solid var(--second-color);
  }

  .selected_chair {
    background-color: #b85f85 !important;
    border: 2px solid #b85f85 !important;
  }

  .title_box {
    color: var(--black-color);
    font-family: var(--fuente-primary);
    font-weight: 700;
    font-size: 18px;
  }
</style>