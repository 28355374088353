<template>
  <b-form @submit.prevent="saveData" ref="form" autocomplete="off">
    <b-row v-if="isMessageMoved">
      <b-col lg="12" md="12" sm="12" class="mb-3">
        <p v-if="isBooking2" class="text__ mb-0">
          No se pueden mover reservas que aún deben dinero.
        </p>
        <p for="name" class="text__ mb-0" v-else>
          Sólo se pueden mover ventas, del mísmo tipo (Tour, Solo ida, Solo
          regreso).
        </p>
      </b-col>
    </b-row>
    <template v-else>
      <b-row class="mb-2">
        <b-col lg="12" md="12" sm="12">
          <date-picker
            v-model="formData.date"
            class="margin-right"
            :attributes="attrs"
            :model-config="modelConfig"
            :available-dates="{ start: new Date(), end: null }"
            @input="loadTravels"
          >
            <template v-slot="{ inputValue, inputEvents }">
              <b-row>
                <b-col lg="12" md="12" sm="12">
                  <div role="group">
                    <label class="label_input">Fecha de salida</label>
                    <b-form-input
                      size="sm"
                      :value="inputValue"
                      v-on="inputEvents"
                      readonly
                    />
                  </div>
                </b-col>
              </b-row>
            </template>
          </date-picker>
        </b-col>
        <b-col lg="12" md="12" sm="12" class="mb-3">
          <div role="group">
            <label class="label_input">Transporte</label>
            <v-select
              v-model="travelSelected"
              :options="travels"
              label="name"
              class="style-chooser"
              placeholder="Seleccione"
              @input="loadSalesByTravel"
            >
              <div slot="no-options">Sin opciones</div>
            </v-select>
          </div>
        </b-col>
        <b-col lg="6" md="12" sm="12" class="mb-3">
          <div role="group">
            <label class="label_input">Silla disponibles</label>
            <b-form-input size="sm" disabled v-model="formData.availables" />
          </div>
        </b-col>
        <b-col lg="6" md="12" sm="12" class="mb-3">
          <div role="group">
            <label class="label_input">N° de ventas a mover</label>
            <b-form-input
              size="sm"
              disabled
              :value="cuantityChairSale()"
            />
          </div>
        </b-col>
        <b-col
          lg="12"
          md="12"
          sm="12"
          class="mb-3"
          v-if="isDisabled2 || isNotChairs"
        >
          <div class="panel__msg--error">
            <small class="msg__error mt-1">
              {{ messageError2() }}
            </small>
          </div>
        </b-col>
      </b-row>
      <b-row class="footer-modal-customer" v-if="!isDisabled2">
        <b-col cols="12" class="d-flex justify-content-end">
          <b-button
            type="submit"
            size="lg"
            class="btn btn-success w-width"
            :disabled="isBusy || $v.$invalid"
          >
            <b-spinner class="mr-2" v-if="isBusy" small />
            Guardar
          </b-button>
        </b-col>
      </b-row>
    </template>
  </b-form>
</template>

<script>
import {
  BCol,
  BRow,
  BButton,
  BForm,
  BSpinner,
  BFormInput,
} from "bootstrap-vue";
import vSelect from "vue-select";
import DatePicker from "v-calendar/lib/components/date-picker.umd";
import { required } from "vuelidate/lib/validators";
import { mapGetters } from "vuex";
import MessageMixin from "@/mixins/messageMixin";

export default {
  inject: ["travelRepository", "saleRepository"],
  mixins: [MessageMixin],
  components: {
    BCol,
    BRow,
    BButton,
    BForm,
    BFormInput,
    BSpinner,
    vSelect,
    DatePicker,
  },
  props: {
    moveSales: {
      type: Array,
      default: null,
    },
    travel: {
      type: Object,
      default: null,
    },
    isMessageMoved: {
      type: Boolean,
      default: false,
    },
    isBooking2: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      travelUuid: this.$route.params.travelUuid,
      isBusy: false,
      isDisabled: false,
      isDisabled2: false,
      formData: {
        travelUuid: null,
        availables: null,
        availables2: [],
        moveSales: [],
        chairSales: 0,
        typeSale: null,
        date: "",
      },
      travels: [],
      travelSelected: null,
      modelConfig: {
        type: "string",
        mask: "YYYY-MM-DD",
      },
      attrs: [
        {
          highlight: "orange",
          dates: new Date(),
        },
      ],
      isNotChairs: false,
    };
  },
  computed: {
    ...mapGetters("user", ["getEnterprise"]),
  },
  validations: {
    formData: {
      travelUuid: {
        required,
      },
      availables: {
        required,
      },
    },
  },
  methods: {
    cuantityChairSale() {
      const me = this;
      const chairSales = [];

      me.moveSales.forEach((el) => {
        el.selecteds.forEach((x) => {
          chairSales.push(x);
        });
      });

      return chairSales.length;
    },
    async loadTravels() {
      const me = this;
      me.isNotChairs = false;
      const typeTransportationUuid = me.travel.transportation.typeTransportation.uuid;

      let isTransportation =
        typeTransportationUuid == "5fa3b241-b713-473b-b412-9b889a456dc5"
          ? true
          : false;

      me.travels = [];
      me.formData.availables = null;
      me.formData.availables2 = [];
      me.travelSelected = null;
      me.formData.travelUuid = null;
      try {
        const { data } = await me.travelRepository.getAllTravelEnterpriseByDate(
          me.getEnterprise.uuid,
          me.travelUuid,
          me.formData.date,
          "eq"
        );

        data.forEach((el) => {
          if (el.uuid !== me.travelUuid) {
            if (
              isTransportation &&
              el.transportation.typeTransportation.uuid ==
                "5fa3b241-b713-473b-b412-9b889a456dc5"
            ) {
              me.travels.push({
                uuid: el.uuid,
                name:
                  el.transportation.typeTransportation.name +
                  " " +
                  el.transportation.name +
                  " " +
                  "capacidad: " +
                  el.transportation.capacity,
                rows: el.transportation.row,
                capacity: el.transportation.capacity,
              });
            } else if (
              !isTransportation &&
              el.transportation.typeTransportation.uuid !==
                "5fa3b241-b713-473b-b412-9b889a456dc5"
            ) {
              me.travels.push({
                uuid: el.uuid,
                name:
                  el.transportation.typeTransportation.name +
                  " " +
                  el.transportation.name +
                  " " +
                  "capacidad: " +
                  el.transportation.capacity,
                rows: el.transportation.row,
                capacity: el.transportation.capacity,
              });
            }
          }
        });
      } catch (error) {
        const { status } = error.data;
        if (status == 419) {
          me.$bvModal.show("expired-token-model");
        } else if (status == 403) {
          me.$bvModal.show("session-finish-modal");
        }
      }
    },
    async loadSalesByTravel() {
      const me = this;
      if (me.travelSelected.rows.length > 0) {
        try {
          me.formData.availables = null;
          me.formData.chairSales = me.cuantityChairSale();
          me.formData.availables2 = [];
          me.formData.travelUuid = me.travelSelected.uuid;

          const { data } = await me.saleRepository.getAll(
            me.travelSelected.uuid
          );

          me.formData.typeSale = me.moveSales[0].selecteds[0].typeSale;

          if (data.length > 0) {
            me.occupiedChairs(data);
          } else {
            me.travelSelected.rows.forEach((el) => {
              el.chair.forEach((chair) => {
                me.formData.availables2.push({
                  uuid: chair.uuid
                });
              });
            });
            me.formData.availables = me.travelSelected.capacity;
          }
        } catch (error) {
          const { status } = error.data;
          if (status == 419) {
            me.$bvModal.show("expired-token-model");
          } else if (status == 403) {
            me.$bvModal.show("session-finish-modal");
          }
        }
      } else {
        me.isNotChairs = true;
      }
    },
    occupiedChairs(sales) {
      const me = this;

      me.travelSelected.rows.forEach((el) => {
        el.chair.forEach((chair) => {
          sales.forEach((chairSal) => {
            chairSal.chairSale.forEach((x) => {
              if (me.formData.typeSale == "1") {
                if (x.chairUuid == chair.uuid) {
                  chair.occupancyStatus = true;
                }
              } else if (
                (x.chairUuid == chair.uuid && x.typeSale == "1") ||
                (x.chairUuid == chair.uuid &&
                  x.typeSale == me.formData.typeSale)
              ) {
                chair.occupancyStatus = true;
              }
            });
          });
        });
      });

      const availables = [];

      me.travelSelected.rows.forEach((el) => {
        el.chair.forEach((chair) => {
          if (!chair.occupancyStatus) {
            availables.push(chair);
          }
        });
      });

      me.formData.availables = availables.length;
      me.formData.availables2 = availables;

      me.isDisabled2 =
        availables.length < me.formData.chairSales.length ? true : false;
    },
    async saveData() {
      const me = this;
      let title, messageErr;
      try {
        me.isBusy = true;
        me.formData.moveSales = me.moveSales.map((item => {
          return {
            sale: item.sale,
            saleUuid: item.saleUuid,
            selecteds: item.selecteds.map((x => {
              delete x.chairSaleAccompanist.accompanist.uuid
              return {
                uuid: x.uuid,
                chairUuid: x.chairUuid,
                planClientUuid: x.planClientUuid,
                sale_price: x.sale_price,
                typeSale: x.typeSale,
                accompanist: x.chairSaleAccompanist.accompanist,
                enterpriseMove: x.enterpriseMove,
                userUuid: x.userUuid
              }
            }))
          }
        }));
        await me.saleRepository.moveSale(me.formData);
        title = "Ventas movidas";
        messageErr = "Ventas movidas con exito.";
        me.$emit(
          "saleUpdate",
          { title, messageErr },
          "move-sale-modal"
        );
      } catch (error) {
        const me = this;
        const { status, message } = error.data;
        if (status == 419) {
          me.$bvModal.show("expired-token-model");
        } else if (status == 403) {
          me.$bvModal.show("session-finish-modal");
        }

        title = "Error al guardar registro";
        messageErr = "Registro no guardado.";

        if (message == "Chair is occupied") {
          title = "Error silla(s) ocupada(s)";
          messageErr = "No fue posible mover todas las ventas.";
          me.$emit("saleUpdate", null);
        } 
        me.messageError(title, messageErr);

      } finally {
        me.isBusy = false;
      }
    },
    messageError2() {
      const me = this;
      let message = null;

      if (me.isNotChairs) {
        message = "El transporte seleccionado no tiene sillas agregadas.";
      } else {
        message = `Las sillas disponibles de este transporte no cumplen con la cantidad de
          ventas que deseas mover. Por favor escoge otra transporte o selecciona
          un número menor de ventas a mover.`;
      }
      return message;
    },
  },
};
</script>

<style lang="css">
.box_number_ {
  border: 1px solid;
  padding: 3px;
  font-size: 14px;
  border-radius: 0.2rem;
  color: var(--black-color);
  background: rgb(204, 202, 202);
}
</style>
