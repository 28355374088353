<template>
  <b-card no-body class="card-global overflow-hidden">
    <b-row>
      <b-col lg="12" md="12" sm="12" class="mb-2">
        <div class="contents-button-sales">
          <b-button
            v-if="hasPermission(module, 'moveSale')"
            class="btn-icon mr-2 mb-3 background-btn-2"
            size="lg"
            :disabled="isDisabled"
            @click.prevent="() => $emit('moveSale', selected)"
          >
            <b-icon-arrow-repeat class="mr-1" />
            Mover tiquetes a otro tour
          </b-button>
          <b-button
            v-if="hasPermission(module, 'moveSaleEnterprise')"
            class="btn-icon mr-2 mb-3 background-btn-3"
            size="lg"
            :disabled="isDisabled"
            @click.prevent="() => $emit('moveSaleEnterprise', selected)"
          >
            <b-icon-arrow-repeat class="mr-1" />
            Mover tiquetes a otra empresa
          </b-button>
          <b-button
            v-if="hasPermission(module, 'report')"
            class="btn-icon mb-3 mr-2 background-btn-4"
            size="lg"
            @click.prevent="modalNumberSarper('1')"
            :disabled="records.length > 0 ? false : true"
          >
            <b-spinner
              class="mr-1"
              v-if="typeRequest == '1' && isBusy2"
              style="width: 1.5rem; height: 1.5rem"
            />
            <b-icon-printer-fill class="mr-1" v-else />
            Planilla
          </b-button>
          <b-button
            v-if="
              hasPermission(module, 'report') &&
              typeTransport === '5fa3b241-b713-473b-b412-9b889a456dc5'
            "
            class="btn-icon mb-3 background-btn-5"
            size="lg"
            @click.prevent="modalNumberSarper('2')"
            :disabled="records.length > 0 ? false : true"
          >
            <b-spinner
              class="mr-1"
              v-if="typeRequest == '2' && isBusy2"
              style="width: 1.5rem; height: 1.5rem"
            />
            <b-icon-printer-fill class="mr-1" v-else />
            Planilla Capitán
          </b-button>
        </div>
      </b-col>
      <b-col lg="8" sm="12"></b-col>
      <b-col lg="4" md="8" sm="12" class="mb-3 mt-3">
        <div role="group" class="d-flex">
          <b-form-input
            size="lg"
            v-model="filter"
            type="search"
            placeholder="Buscar"
            class="mr-3"
          />
          <b-input-group-append>
            <b-button
              variant="danger"
              size="md"
              :disabled="!filter"
              @click="filter = ''"
            >
              Limpiar
            </b-button>
          </b-input-group-append>
        </div>
      </b-col>
      <b-col lg="12" md="12" sm="12" class="mb-2">
        <div class="contents-button-sales">
          <div class="d-flex align-items-center mr-3 mb-1">
            <div class="circle bg-primary"></div>
            <span class="ml-2 font-weight-bold">Información por llenar</span>
          </div>
          <div class="d-flex align-items-center mr-3 mb-1">
            <div class="circle bg-success"></div>
            <span class="ml-2 font-weight-bold">Pagos pendientes</span>
          </div>
          <div class="d-flex align-items-center mr-3 mb-1">
            <div class="circle bg-danger"></div>
            <span class="ml-2 font-weight-bold">Tiquetes Eliminados</span>
          </div>
          <div class="d-flex align-items-center mr-3 mb-1">
            <div class="circle background-btn-2"></div>
            <span class="ml-2 font-weight-bold"
              >Tiquetes movidos a otro tour</span
            >
          </div>
          <div class="d-flex align-items-center mb-1">
            <div class="circle background-btn-3"></div>
            <span class="ml-2 font-weight-bold"
              >Tiquetes movidos a otra empresa</span
            >
          </div>
        </div>
      </b-col>
    </b-row>

    <div class="content-table-sales">
      <b-table
        ref="selectableTable"
        class="table-location"
        striped
        responsive
        bordered
        small
        show-empty
        :fields="fields"
        :items="records"
        :filter="filter"
        :per-page="dataPerPage"
        :current-page="pagination.currentPage"
        :busy="busy"
        :filter-included-fields="filterOn"
        :sort-desc.sync="sortDesc"
        :sort-direction="sortDirection"
        @filtered="onFiltered"
      >
        <template #table-busy>
          <div class="text-center text-success my-2">
            <b-spinner class="align-middle mr-1" />
            <strong>Cargando datos...</strong>
          </div>
        </template>
        <template #empty>
          <h6 class="text-center py-2">No hay registros</h6>
        </template>
        <template #cell(show_details)="row">
          <div class="d-flex align-items-center">
            <b-button
              size="lg"
              @click="row.toggleDetails"
              class="btn-icon-detailt mr-2"
            >
              <b-icon-chevron-up v-if="row.detailsShowing" />
              <b-icon-chevron-down v-else />
            </b-button>
            <div
              v-if="isAccompanist(row.item)"
              class="circle bg-primary mr-2"
            ></div>
            <div
              v-if="isChairSaleMoveTour(row.item)"
              class="circle background-btn-2 mr-2"
            ></div>
            <div
              v-if="isChairSaleMoveTourEnterprise(row.item)"
              class="circle background-btn-3 mr-1"
            ></div>
            <div
              v-if="isChairSaleDelete(row.item)"
              class="circle bg-danger ml-1"
            ></div>
          </div>
        </template>
        <template #row-details="row">
          <location-table-chair
            @addSelect="addSelect"
            :uuidSale="row.item.uuid"
            :sale="row.item"
            :records="dataChair(row.item.chairSale)"
            :selecteds="selected"
            @detailChairSale="detailChairSale"
            @editChairSale="editChairSale"
            @deleteChairSale="deleteChairSale"
            @addBaby="addBaby"
          />
        </template>
        <template #cell(chairSale)="data">
          <div class="d-flex">
            <b-badge
              :class="loadColor(chair)"
              class="mr-1 badge-size"
              v-for="chair in dataChair(data.item.chairSale)"
              :key="chair.uuid"
            >
              {{ chair.chair.number_chair }}
            </b-badge>
          </div>
        </template>
        <template #cell(client)="data">
          {{ data.item.client.names + " " + data.item.client.last_names }}
        </template>
        <template #cell(sale_price)="data">
          {{ formatPrice(data.item.sale_price) }}
        </template>
        <template #cell(total)="data">
          {{ loadTotal(data.item) }}
        </template>
        <template #cell(is_total)="data">
          <h5 class="d-flex align-items-center">
            <b-badge :class="statusColor(data.item.is_total)">
              {{ data.item.is_total ? "Reserva" : "Venta" }}
            </b-badge>
            <b-spinner
              v-if="data.item.is_deb"
              class="ml-2"
              small
              type="grow"
              variant="success"
              label="Loading..."
            ></b-spinner>
          </h5>
        </template>
        <template #cell(status)="data">
          <b-badge
            :class="
              data.item.status == 'Activo' ? 'badge-success' : 'badge-danger'
            "
          >
            {{ data.item.status }}
          </b-badge>
        </template>
        <template #cell(actions)="data">
          <b-button
            :id="'p-' + data.item.uuid"
            class="btn-icon actions_ margin-right"
            size="sm"
          >
            <b-icon-three-dots-vertical />
          </b-button>
          <Popover
            :target="'p-' + data.item.uuid"
            :record="data.item"
            :isTourRealized="false"
            :module="module"
            :status="data.item.status"
            @listPayments="listPayments"
            @detail="detailSale"
            @edit="editSale"
            @delete="deleteSale"
          />
        </template>
      </b-table>
    </div>
    <b-row v-if="false">
      <b-col cols="12" class="my-1">
        <b-pagination
          v-model="pagination.currentPage"
          :total-rows="pagination.totalRows"
          :per-page="pagination.perPage"
          pills
          align="center"
          size="sm"
          class="my-0"
          prev-text="Anterior"
          next-text="Siguiente"
        ></b-pagination>
      </b-col>
    </b-row>
    <base-modal :size="'sm'" title="Número de zarpe" id="modal-sarper">
      <template v-slot:form>
        <form-sarper-number @generarPdf="generarPdf" />
      </template>
    </base-modal>
    <base-modal :title="title" id="modal-chair-sale">
      <template v-slot:form>
        <form-edit-detail-chair-sale
          :record="record"
          :isDisabled="isDisabled2"
          @updateChairSale="() => $emit('updateChairSale')"
        />
      </template>
    </base-modal>
    <base-modal title="Bebes" id="modal-baby">
      <template v-slot:form>
        <Baby :baby="baby" />
      </template>
    </base-modal>
    <base-modal title="Eliminar Tiquete" id="modal-delete-chair-sale">
      <template v-slot:form>
        <form-chair-sale-delete
          :chairSale="chairSale"
          @updateChairSale2="() => $emit('updateSales')"
        />
      </template>
    </base-modal>
  </b-card>
</template>

<script lang="js">
import { API_ROUTES } from "@/config";
import PermissionAction from "@/mixins/PermissionAction";
import MessageMixin from "@/mixins/messageMixin";
import { mapGetters } from "vuex";
//Components
import {
  BTable,
  BCol,
  BRow,
  BFormInput,
  BInputGroupAppend,
  BButton,
  BPagination,
  BSpinner,
  BCard,
  BIconThreeDotsVertical,
  BIconPrinterFill,
  BIconArrowRepeat,
  BIconChevronDown,
  BIconChevronUp,
  BBadge,
} from "bootstrap-vue";
import FormSarperNumber from "./FormSarperNumber";
import BaseModal from "@/components/base/BaseModal";
import Popover from "./Popover";
import LocationTableChair from "./LocationTableChair";
import FormEditDetailChairSale from "./FormEditDetailChairSale";
import Baby from "./Baby";
import FormChairSaleDelete from "./FormChairSaleDelete";
export default {
  inject: ["saleRepository", "chairSaleRepository"],
  mixins: [PermissionAction, MessageMixin],
  components: {
    BTable,
    BCol,
    BRow,
    BFormInput,
    BInputGroupAppend,
    BButton,
    BPagination,
    BCard,
    BSpinner,
    BIconThreeDotsVertical,
    BIconPrinterFill,
    BIconArrowRepeat,
    BIconChevronDown,
    BIconChevronUp,
    BBadge,
    Popover,
    FormSarperNumber,
    BaseModal,
    LocationTableChair,
    FormEditDetailChairSale,
    Baby,
    FormChairSaleDelete,
  },
  props: {
    records: {
      type: Array,
      default: () => [],
    },
    busy: {
      type: Boolean,
      default: false,
    },
    isTourRealized: {
      type: Boolean,
      default: false,
    },
    typeTransport: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      travelUuid: this.$route.params.travelUuid,
      filter: null,
      pagination: {
        perPage: 10,
        pageOptions: [10, 20, 50],
        totalRows: 1,
        currentPage: 1,
      },
      filterOn: [],
      selected: [],
      sortDesc: false,
      statusAll: false,
      sortDirection: "asc",
      fields: [
        {
          key: "show_details",
          label: "",
        },
        {
          key: "chairSale",
          label: "N° Silla",
        },
        {
          key: "client",
          label: "Cliente",
          sortable: true,
        },
        {
          key: "client.identification",
          label: "Identificación",
          sortable: true,
        },
        {
          key: "is_total",
          label: "Venta/Reserva",
          sortable: true,
        },
        {
          key: "status",
          label: "Estado",
          sortable: true,
        },
        {
          key: "total",
          label: "Total venta",
          sortable: true,
        },
        { key: "actions", label: "Accion" },
      ],
      module: "0cea77ef-98ac-4fc5-980f-bca65e552359",
      typeRequest: "1",
      isDisabled: true,
      isBusy2: false,
      record: null,
      chairSale: null,
      isDisabled2: false,
      title: null,
      baby: null,
    };
  },
  watch: {
    records() {
      this.statusAll = false;
    },
  },
  computed: {
    ...mapGetters("user", ["getUser"]),
    dataPerPage() {
      const me = this;
      return me.paginate ? me.pagination.perPage : me.records.length;
    }
  },
  mounted() {
    const me = this;
    me.pagination.totalRows = me.records.length;
  },
  methods: {
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length;
      this.pagination.currentPage = 1;
    },
    listPayments(item) {
      this.$emit("listPayments", item);
    },
    detailSale(item) {
      this.$emit("detailSale", item);
    },
    editSale(item) {
      this.$emit("editSale", item);
    },
    deleteSale(uuid) {
      this.$emit("deleteSale", uuid);
    },
    modalNumberSarper(type) {
      const me = this;
      if (me.typeTransport === '5fa3b241-b713-473b-b412-9b889a456dc5') {
        me.typeRequest = type;
        me.$bvModal.show("modal-sarper");
      } else {
        me.generarPdf({ numberSarper: null })
      }
    },
    async generarPdf(formData) {
      const me = this;
      me.$bvModal.hide("modal-sarper");
      const option = me.typeRequest == "1" ? "generarReport" : "generarReportPilot";
      try {
        me.isBusy2 = true;
        const { data } = await me.saleRepository[option](
          me.travelUuid,
          formData.numberSarper
        );
        const url = `${API_ROUTES.report.get}${data.url}`;
        window.open(url, "_blank");
      } catch (error) {
        const { status } = error.data;
        if (status == 419) {
          me.$bvModal.show("expired-token-model");
        } else if (status == 403) {
          me.$bvModal.show("session-finish-modal");
        }
      } finally {
        me.isBusy2 = false;
      }
    },
    statusColor(value) {
      let variant = value ? "background-reservation" : "background-sale";

      return variant;
    },
    formatPrice(price) {
      const formatterPeso = new Intl.NumberFormat("es-CO", {
        style: "currency",
        currency: "COP",
        minimumFractionDigits: 0,
      });

      return formatterPeso.format(parseFloat(price));
    },
    detailChairSale(item) {
      const me = this;
      me.title = "Detalle tour / pasajero";
      me.isDisabled2 = true;
      const accompanist = { ...item.chairSaleAccompanist.accompanist };
      me.record = { ...me.record, ...item, ...accompanist };
      me.$bvModal.show("modal-chair-sale");
    },
    editChairSale(item) {
      const me = this;
      me.title = "Actualizar tour / pasajero";
      const uuidChairSale = item.uuid;
      me.isDisabled2 = false;
      const accompanist = { ...item.chairSaleAccompanist.accompanist };
      me.record = { ...me.record, ...item, ...accompanist, uuidChairSale };
      me.$bvModal.show("modal-chair-sale");
    },
    deleteChairSale(item) {
      const me = this;
      me.chairSale = item;
      me.$bvModal.show("modal-delete-chair-sale");
    },
    addBaby(item) {
      const me = this;
      me.baby = {
        accompanistUuid: item.chairSaleAccompanist.accompanist.uuid,
      };
      me.$bvModal.show("modal-baby");
    },
    addSelect(item) {
      const me = this;

      const sale = me.selected.find(el => el.saleUuid == item.saleUuid);

      if (sale) {
        me.selected.forEach(el => {
          if(el.saleUuid == sale.saleUuid) {
            el.selecteds = item.selecteds;
          }
        });
      } else {
        me.selected.push(item);
      }

      me.selected.forEach((el, index) => {
        if(el.selecteds.length == 0) {
          me.selected.splice(index, 1);
        }
      });

      me.isDisabled = me.selected.length > 0 ? false : true;
    },
    loadColor(item) {
      let variant = "";
      if (item.status == "Activo") {
        variant = "badge-warning";
      } else if (item.status == "moveTour") {
        variant = "background-btn-2"
      } else if (item.status == "moveTourEnterprise") {
        variant = "background-btn-3"
      } else {
        variant = "badge-danger";
      }
      return variant;
    },
    loadTotal(item) {
      const me = this;
      let totalSale = 0;

      // if (me.getUser.profile.uuid == "mam783bb-275c-21m4-912b-24c3d0m547e2") {
      //   let totalchairSale = 0;
      //   item.chairSale.forEach(x => {
      //     if (x.userUuid == me.getUser.uuid) {
      //       totalchairSale += parseInt(x.sale_price);
      //     }
      //   });
      //   totalSale = totalchairSale;
      // } else {
        totalSale = item.total;
      // }
      return me.formatPrice(totalSale);
    },
    dataChair(items) {
      const me = this;
      let chairSales = [];

      // if (me.getUser.profile.uuid == "mam783bb-275c-21m4-912b-24c3d0m547e2") {
      //   items.forEach(x => {
      //     if (x.userUuid == me.getUser.uuid) {
      //       chairSales.push(x);
      //     }
      //   });
      // } else {
        chairSales = items;
      // }
      return chairSales;
    },
    isAccompanist(item) {
      const record = item.chairSale.find((x) => !x.chairSaleAccompanist.accompanist.names && x.status === 'Activo')
      return record ? true : false
    },
    isChairSaleDelete(item) {
      const record = item.chairSale.find((x) => x.status === 'deleteTicket' || x.status === 'deleteTicketWithDevol')
      return record ? true : false
    },
    isChairSaleMoveTour(item) {
      const record = item.chairSale.find((x) => x.status === 'moveTour')
      return record ? true : false
    },
    isChairSaleMoveTourEnterprise(item) {
      const record = item.chairSale.find((x) => x.status === 'moveTourEnterprise')
      return record ? true : false
    }
  },
};
</script>
<style lang="css">
.content-table-sales {
  max-height: 400px;
  overflow-y: auto;
}

/* Estilos para motores Webkit y blink (Chrome, Safari, Opera... )*/

/*el elemento que envuelve al conjunto*/
.content-table-sales::-webkit-scrollbar {
  -webkit-appearance: none;
}

/*barras de scroll verticales*/
.content-table-sales::-webkit-scrollbar:vertical {
  width: 5px;
}

/*la barra y los botones*/
.content-table-sales::-webkit-scrollbar-button:increment,
.content-table-sales::-webkit-scrollbar-button {
  display: none;
}

.content-table-sales::-webkit-scrollbar-thumb {
  background-color: #949292;
  border-radius: 20px;
  border: none;
  /*height: 100px !important;*/
}

/*el carril de la barra*/
.content-table-sales::-webkit-scrollbar-track {
  border-radius: 10px;
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
}

.table > thead {
  background: var(--fourth-color);
  color: var(--white-color);
  font-family: var(--fuente-primary);
  font-size: 16px;
  text-align: center;
}

.table > tbody {
  color: var(--black-color);
  font-family: var(--fuente-primary);
  font-size: 16px;
}

.table-location .table > tbody > tr > td:first-child {
  width: 150px;
}

.actions_ {
  background: var(--white-color) !important;
  border: 1px solid var(--second-color) !important;
  color: var(--black-color) !important;
  font-weight: bold !important;
}

.icon_check {
  color: var(--fourth-color);
  font-size: 25px;
}

.btn-icon-detailt {
  background: var(--third-color) !important;
  border: none !important;
}

.contents-button-sales {
  display: flex;
  flex-wrap: wrap;
}

.badge-size {
  font-size: 15px !important;
}

/*** VERSION CELULAR ***/
@media only screen and (max-width: 767px) {
  .contents-button-sales {
    flex-direction: column !important;
  }

  .contents-button-sales button {
    width: 100% !important;
  }
}
</style>
