<template>
  <b-form @submit.prevent="saveData" ref="form" autocomplete="off">
    <b-row class="mb-3">
      <b-col lg="6" md="12" sm="12" class="mb-3">
        <div role="group">
          <label for="sale_price" class="label_input">Precio de venta</label>
          <b-form-input
            id="sale_price"
            size="sm"
            :value="formatPrice(chairSale.sale_price)"
            disabled
          />
        </div>
      </b-col>
      <b-col lg="12" md="12" sm="12" class="mb-3" v-if="chairSale.is_deb">
        <div role="group">
          <b-form-checkbox
            v-model="formData.is_discount"
            id="checkbox-1"
            name="checkbox-1"
            size="lg"
          >
            <div class="subtitle text-white position-relative">
              ¿Quieres que el valor de este tiquete se descuente del monto a pagar?
            </div>
          </b-form-checkbox>
        </div>
      </b-col>
      <b-col lg="12" md="12" sm="12" class="mb-3" v-if="!chairSale.is_deb">
        <div role="group">
          <b-form-checkbox
            v-model="formData.isDevolution"
            id="checkbox-1"
            name="checkbox-1"
            size="lg"
          >
            <div class="subtitle text-white position-relative">
              Realizar Devolución de dinero
            </div>
          </b-form-checkbox>
        </div>
      </b-col>
    </b-row>
    <b-row v-if="formData.isDevolution">
      <b-col lg="12" md="12" sm="12" class="mb-3">
        <div class="label_input text-white">LLena esta información solo si realizarás un devolución de dínero por este tiquete que vas a eliminar.</div>
      </b-col>
      <b-col lg="6" md="12" sm="12" class="mb-3">
        <div role="group">
          <label for="discount" class="label_input">Monto a devolver*</label>
          <b-form-input
            id="discount"
            size="sm"
            v-model="formData.discount"
            @keyup="calculateTotal"
          />
          <span v-if="isMajorReturn" class="help-block text-white text_error">
            El monto a devolver no puede ser mayor al precio de venta.
          </span>
        </div>
      </b-col>
      <b-col lg="6" md="12" sm="12" class="mb-3">
        <div role="group">
          <label for="payment_method" class="label_input"
            >Método de pago*</label
          >
          <v-select
            :options="typeOfPayments"
            :reduce="(option) => option.id"
            label="name"
            class="style-chooser"
            placeholder="Seleccione"
            v-model="formData.payment_method"
          >
            <div slot="no-options">Sin opciones</div>
          </v-select>
        </div>
      </b-col>
    </b-row>
    <b-row class="footer-modal-customer">
      <b-col cols="12" class="d-flex justify-content-end">
        <b-button
          type="submit"
          size="lg"
          class="btn btn-success w-width"
          :disabled="isBusy || (formData.isDevolution && $v.$invalid)"
        >
          <b-spinner class="mr-2" v-if="isBusy" small />
          Eliminar
        </b-button>
      </b-col>
    </b-row>
  </b-form>
</template>

<script>
import {
  BCol,
  BRow,
  BButton,
  BForm,
  BSpinner,
  BFormInput,
  BFormCheckbox,
} from "bootstrap-vue";
import vSelect from "vue-select";
import { required } from "vuelidate/lib/validators";
import { formatNumber } from "@/utils";
import typePayments from "@/data/typePayments.json";
import messageMixin from "@/mixins/messageMixin";
export default {
  inject: ["chairSaleRepository"],
  mixins: [messageMixin],
  components: {
    BCol,
    BRow,
    BButton,
    BForm,
    BFormInput,
    BSpinner,
    BFormCheckbox,
    vSelect,
  },
  props: {
    chairSale: {
      type: Object,
      default: null,
    }
  },
  data() {
    return {
      isBusy: false,
      isDisabled: false,
      formData: {
        payment_method: null,
        sale_price: null,
        discount: null,
        isDevolution: false,
        is_discount: false,
      },
      typeOfPayments: typePayments,
      isMajorReturn: false,
      discount: 0,
    };
  },
  validations:{
    formData: {
      payment_method: {
        required
      },
      discount: {
        required
      }
    }
  },
  methods: {
    async saveData() {
      const me = this;
      me.$bvModal
        .msgBoxConfirm("¿Seguro que deseas eliminar este registro?", {
          title: "Confirmar eliminación",
          size: "sm",
          buttonSize: "sm",
          okVariant: "danger",
          okTitle: "Si",
          cancelTitle: "No",
          footerClass: "p-2",
          hideHeaderClose: false,
          centered: true,
        })
        .then(async (value) => {
          if (value) {
            try {
              await me.chairSaleRepository.delete(me.chairSale.uuid, me.formData);
              me.$emit("updateChairSale2");
            } catch (error) {
              const { status } = error.data;
              if (status == 419) {
                me.$bvModal.show("expired-token-model");
              } else if (status == 403) {
                me.$bvModal.show("session-finish-modal");
              }
            }
          }
        });
    },
    formatPrice(price) {
      return formatNumber(price);
    },
    calculateTotal() {
      const me = this;
      me.isMajorReturn = false;
      const salePrice = Number(me.chairSale.sale_price);
      me.discount = Number(me.formData.discount?.split(".").join(""));
      
      if (me.discount > salePrice) {
        me.formData.discount = null;
        me.isMajorReturn = true;
      } else {
        me.formData.discount = me.formatPrice(me.discount + '');
      }
    }
  },
};
</script>
