<template>
  <base-action-table
      :records="records"
      :fields="fields"
      :busy="isBusy"
      :isVisible="false"
      :filtering="false"
    >

      <template #cell(cuantity)="data">
        {{ data.item.totals.length }}
      </template>
      <template #cell(total)="data">
        <span :class="{ 'text-danger': priceNegative(data.item.total) }">
          {{ formatPrice(data.item.total)}}
        </span>
      </template>
    </base-action-table>
</template>

<script>
import BaseActionTable from "@/components/base/BaseActionTable";
  export default {
    props: {
      records: {
        type: Array,
        default: () => [],
      },
      fields: {
        type: Array,
        default: () => [],
      },
      isBusy: {
        type: Boolean,
        default: false
      }
    },
    components: {
      BaseActionTable,
    },
    methods: {
      formatPrice(price) {
        const formatterPeso = new Intl.NumberFormat("es-CO", {
          style: "currency",
          currency: "COP",
          minimumFractionDigits: 0,
        });

        return formatterPeso.format(parseFloat(price));
      },
      priceNegative(value) {
        const isUtilityNegative =
          Math.sign(value) === 1 || Math.sign(value) === 0 ? false : true;

        return isUtilityNegative;
      },
    }
  }
</script>