<template>
  <b-form @submit.prevent="saveLiquidation" ref="form" autocomplete="off">
    <b-row class="mb-3">
      <b-col lg="12" md="12" sm="12">
        <div class="label_input text-white">* Campos requeridos.</div>
      </b-col>
      <b-col lg="12" md="12" sm="12" class="mb-3">
        <div role="group">
          <label class="label_input">Tipos de Gastos*</label>
          <v-select
            :options="variables"
            multiple
            label="name"
            class="style-chooser style-chooser-multiple"
            placeholder="Seleccione"
            v-model="formData.variables_costs"
            :disabled="isDisabled"
            @input="calculateTotal"
          >
            <div slot="no-options">Sin opciones</div>
          </v-select>
        </div>
      </b-col>
    </b-row>
    <b-row class="mb-4" v-if="formData.variables_costs.length > 0">
      <b-col lg="12" md="12" sm="12" class="mb-2">
        <div class="text-liquidation">Gastos</div>
      </b-col>
      <b-col
        lg="12"
        md="12"
        sm="12"
        class="mb-3"
        v-for="item in formData.variables_costs"
        :key="item.uuid"
      >
        <div role="group">
          <label :for="item.uuid" class="label_input">{{ item.name }}*</label>
          <b-form-input
            :id="item.uuid"
            size="sm"
            v-model="item.value"
            :disabled="isDisabled"
            @keyup="calculateTotal(item.uuid)"
          />
        </div>
      </b-col>
    </b-row>
    <hr />
    <b-row class="mb-4">
      <div class="pl-4">
        <div class="d-flex align-items-center">
          <div class="text-liquidation mr-1">Total gastos:</div>
          <div class="value-liquidation">
            {{ formatTotal(formData.total_costs) }}
          </div>
        </div>
        <div class="d-flex align-items-center">
          <div class="text-liquidation mr-1">Total a liquidar:</div>
          <div class="value-liquidation">{{ formatTotal(totalSales) }}</div>
        </div>
        <div class="d-flex align-items-center">
          <div class="text-liquidation mr-1">Utilidad:</div>
          <div
            class="value-liquidation"
            :class="{ 'text-danger': isUtilityNegative }"
          >
            {{ isUtilityNegative ? "-" : "" }}
            {{ formatUtility(formData.utility) }}
          </div>
        </div>
      </div>
    </b-row>
    <b-row class="footer-modal-customer" v-if="!isDisabled">
      <b-col cols="12" class="d-flex justify-content-end">
        <b-button
          type="submit"
          size="lg"
          class="btn btn-success w-width"
          :disabled="isBusy || $v.$invalid"
        >
          <b-spinner class="mr-2" v-if="isBusy" small />
          Guardar
        </b-button>
      </b-col>
    </b-row>
  </b-form>
</template>

<script>
import {
  BCol,
  BRow,
  BButton,
  BForm,
  BSpinner,
  BFormInput,
} from "bootstrap-vue";
import vSelect from "vue-select";
import { required } from "vuelidate/lib/validators";
import ActionCRUD from "@/mixins/ActionCRUD";
import { formatNumber, formatPrice } from "@/utils";

export default {
  inject: ["variableLiquidationRepository", "liquidationRepository"],
  components: {
    BCol,
    BRow,
    BButton,
    BForm,
    BFormInput,
    BSpinner,
    vSelect,
  },
  mixins: [ActionCRUD],
  props: {
    record: {
      type: Object,
      default: null,
    },
    isDisabled: {
      type: Boolean,
      default: false,
    },
    totalSales: {
      type: Number,
      default: null,
    },
    travelUuid: {
      type: String,
      default: null,
    },
  },
  data() {
    const me = this;
    return {
      isBusy: false,
      formData: {
        total_costs: 0,
        variables_costs: [],
        total_sales: me.totalSales,
        utility: 0,
        travelUuid: me.travelUuid,
      },
      repository: "liquidationRepository",
      variables: [],
      isUtilityNegative: false,
    };
  },
  validations: {
    formData: {
      total_costs: {
        required,
      },
    },
  },
  methods: {
    async loadVariables() {
      const me = this;
      try {
        const { data } = await me.variableLiquidationRepository.getAll();
        data.forEach((el) => {
          me.variables.push({
            uuid: el.uuid,
            name: el.name,
            value: 0,
          });
        });
      } catch (error) {
        const { status } = error.data;
        if (status == 419) {
          me.$bvModal.show("expired-token-model");
        } else if (status == 403) {
          me.$bvModal.show("session-finish-modal");
        }
      }
    },
    calculateTotal(uuid) {
      const me = this;
      me.formData.total_costs = 0;
      me.formData.variables_costs.forEach((item) => {
        if (item.uuid === uuid) {
          item.value = formatNumber(item.value);
        }
        me.formData.total_costs += item.value > 0 ? Number(item.value?.split(".")?.join("")) : 0;
      });
      me.calculateUtility();
    },
    formatTotal(value) {
      return formatPrice(value + "");
    },
    formatUtility(value) {
      const me = this;
      me.isUtilityNegative =
        Math.sign(value) === 1 || Math.sign(value) === 0 ? false : true;

      if (me.isUtilityNegative) {
        return formatPrice(value + "");
      }

      return formatPrice(Math.abs(value) + "");
    },
    calculateUtility() {
      const me = this;
      me.formData.utility = me.formData.total_sales - me.formData.total_costs;
    },
    saveLiquidation() {
      const me = this;
      me.saveData()
    },
  },
  async mounted() {
    const me = this;
    me.loadVariables();
    if (me.record) {
      me.formData = { ...me.formData, ...me.record };
      me.formData.total_sales = me.totalSales;
      me.formData.variables_costs = JSON.parse(me.record.variables_costs);
      delete me.formData.status;
      delete me.formData.createdAt;
      delete me.formData.travel;
    }
  },
};
</script>
<style>
hr {
  background-color: white;
}
.text-liquidation {
  color: white;
  font-size: 16px;
}

.value-liquidation {
  color: white;
  font-size: 18px;
  font-weight: 700;
}
</style>
