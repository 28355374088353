import { render, staticRenderFns } from "./Totals.vue?vue&type=template&id=5450bcce"
import script from "./Totals.vue?vue&type=script&lang=js"
export * from "./Totals.vue?vue&type=script&lang=js"
import style0 from "./Totals.vue?vue&type=style&index=0&id=5450bcce&prod&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports