<template>
  <b-card no-body class="card-global overflow-hidden">
    <div>
      <b-button
        v-if="hasPermission('7d815776-cb9a-43d7-954f-f9e6b96d80fa', 'new')"
        class="btn-icon mr-2 mb-3 background-btn-1"
        size="lg"
        :disabled="chairSaleAndTotal.length > 0 ? false : true"
        @click.prevent="handleLiquidation"
      >
        <b-icon-calculator-fill class="mr-1" />
        Liquidación
      </b-button>
    </div>
    <location-table-total
      :fields="fields"
      :records="[...chairSaleAndTotal, ...chairSaleOther]"
    />
    <section>
      <div class="content-totals mb-4">
        <div class="d-flex justify-content-between">
          <span class="mr-2">Ventas/reservas</span
          ><span
            ><strong>{{ formatPrice(total(chairSaleAndTotal)) }}</strong></span
          >
        </div>
        <div class="d-flex justify-content-between">
          <span class="mr-2">Total a descontar</span
          ><span :class="{ 'text-danger': priceNegative(total(chairSaleOther)) }"
            ><strong>{{ formatPrice(total(chairSaleOther)) }}</strong></span
          >
        </div>
        <div class="d-flex justify-content-between">
          <span class="mr-2"><strong>Total ventas/reservas</strong></span
          ><span
            ><strong>{{ formatPrice(totalSale) }}</strong></span
          >
        </div>
      </div>
      <div class="content-totals mb-4">
        <div class="d-flex justify-content-between">
          <span class="mr-2">Saldo pendiente</span
          ><span
            ><strong>{{ formatPrice(totalDebt) }}</strong></span
          >
        </div>
        <div class="d-flex justify-content-between">
          <div class="d-flex">
            <span class="mr-2">Ingresos del tour</span>
            <b-button
              v-if="paymentsMade.length > 0"
              class="p-0 btn-detail-payment"
              size="sm"
              @click.prevent="detailPayment"
            >
              <b-icon-eye-fill />
            </b-button>
          </div>
          <span
            ><strong>{{ formatPrice(totalPaymentsMade) }}</strong></span
          >
        </div>
        <div class="d-flex justify-content-between">
          <span class="mr-2">Ingresos de otro tour</span
          ><span><strong>{{ formatPrice(totalPaymentExtra) }}</strong></span>
        </div>
        <div class="d-flex justify-content-between">
          <span class="mr-2"><strong>Total ingresos</strong></span
          ><span><strong>{{ formatPrice(totalIncome) }}</strong></span>
        </div>
      </div>
    </section>
    <div class="content-totals" v-if="profileUuid !== 'mam783bb-275c-21m4-912b-24c3d0m547e2'">
      <div class="d-flex justify-content-between">
        <span class="mr-2"><strong>Total a liquidar</strong></span
        ><span><strong>{{ formatPrice(totalSale) }}</strong></span>
      </div>
      <div class="d-flex justify-content-between">
        <span class="mr-2"><strong>Total dinero restante</strong></span
        ><span :class="{ 'text-danger': priceNegative(totalRestant) }"
          ><strong>{{ formatPrice(totalRestant) }}</strong></span
        >
      </div>
    </div>
    <base-modal title="Listado de pagos ingresados" id="modal-list-payment">
      <template v-slot:form>
        <location-table-total :fields="fields2" :records="paymentsMade" />
      </template>
    </base-modal>
    <base-modal title="Liquidación" id="modal-liquidation">
      <template v-slot:form>
        <list-liquidation :travelUuid="travelUuid" :totalSales="totalSale" />
      </template>
    </base-modal>
  </b-card>
</template>

<script>
import {
  BCard,
  BButton,
  BIconEyeFill,
  BIconCalculatorFill,
} from "bootstrap-vue";
import LocationTableTotal from "./LocationTableTotal.vue";
import ListLiquidation from "../../liquidation/components/ListLiquidation";
import BaseModal from "@/components/base/BaseModal";
import PermissionAction from "@/mixins/PermissionAction";
export default {
  mixins: [PermissionAction],
  components: {
    BCard,
    LocationTableTotal,
    BButton,
    BIconEyeFill,
    BIconCalculatorFill,
    BaseModal,
    ListLiquidation,
  },
  props: {
    chairSaleAndTotal: {
      type: Array,
      default: () => [],
    },
    chairSaleOther: {
      type: Array,
      default: () => [],
    },
    paymentsMade: {
      type: Array,
      default: () => [],
    },
    totalPaymentsMade: {
      type: Number,
      default: 0,
    },
    totalDebt: {
      type: Number,
      default: 0,
    },
    totalPaymentExtra: {
      type: Number,
      default: 0,
    },
    profileUuid: {
      type: String,
      default: "",
    }
  },
  data() {
    return {
      travelUuid: this.$route.params.travelUuid,
      fields: [
        {
          key: "name",
          label: "",
        },
        {
          key: "cuantity",
          label: "Cantidad de tiquetes",
          sortable: true,
        },
        {
          key: "total",
          label: "Total",
          sortable: true,
        },
      ],
      fields2: [
        {
          key: "name",
          label: "Metódo de pago",
        },
        {
          key: "total",
          label: "Total",
          sortable: true,
        },
      ],
    };
  },
  computed: {
    totalSale() {
      const me = this;
      return me.total(me.chairSaleAndTotal) + me.total(me.chairSaleOther);
    },
    totalIncome() {
      const me = this;
      return me.totalPaymentsMade + me.totalPaymentExtra;
    },
    totalRestant() {
      const me = this;
      return me.totalIncome - me.totalSale;
    },
  },
  methods: {
    total(items) {
      return items.reduce((acc, el) => acc + el.total, 0);
    },
    formatPrice(price) {
      const formatterPeso = new Intl.NumberFormat("es-CO", {
        style: "currency",
        currency: "COP",
        minimumFractionDigits: 0,
      });

      return formatterPeso.format(parseFloat(price));
    },
    priceNegative(value) {
      const isUtilityNegative =
        Math.sign(value) === 1 || Math.sign(value) === 0 ? false : true;

      return isUtilityNegative;
    },
    detailPayment() {
      const me = this;
      me.$bvModal.show("modal-list-payment");
    },
    handleLiquidation() {
      const me = this;
      me.$bvModal.show("modal-liquidation");
    },
  },
};
</script>
<style lang="css">
.content-totals {
  width: 350px;
}
.content-totals span {
  font-size: 18px;
}
.btn-detail-payment {
  background: transparent !important;
  color: var(--fifth-color) !important;
  border: none;
  font-size: 20px;
}

/*** VERSION TABLETS ***/
@media only screen and (min-width: 768px) and (max-width: 992px) {
  .content-totals {
    width: 400px !important;
  }
}
/*** VERSION CELULAR ***/
@media only screen and (max-width: 767px) {
  .content-totals {
    width: 100% !important;
  }
}
/*** VERSION CELULAR PEQUEÑO ***/
@media only screen and (max-width: 340px) {
  .content-totals {
    width: 100% !important;
  }
}
</style>
