<template>
  <div v-if="travel">
    <div class="title text-center mb-3">Detalles del tour</div>
    <ButtonTutotial :module="module" />
    <b-row>
      <b-col lg="12" md="12" sm="12" class="mb-3">
        <div class="d-flex justify-content-center">
          <b-button
            v-if="hasPermission(module, 'edit')"
            variant="warning"
            class="btn-icon btn_icon mr-5"
            size="lg"
            @click.prevent="handleEditing(travel)"
          >
            <b-icon-pencil-square />
          </b-button>
          <b-button
            v-if="hasPermission(module, 'delete') && !isTourRealized"
            variant="danger"
            class="btn-icon btn_icon"
            size="lg"
            @click.prevent="handleDeleting(travel.uuid)"
          >
            <b-icon-trash-fill />
          </b-button>
        </div>
      </b-col>
    </b-row>
    <b-row>
      <b-col lg="6" md="6" sm="12" class="mb-2">
        <div role="group">
          <label for="departure_date" class="label_input_black"
            >Fecha de salida</label
          >
          <b-form-input
            id="departure_date"
            size="sm"
            type="date"
            v-model="travel.departure_date"
            disabled
          />
        </div>
      </b-col>
      <b-col lg="6" md="6" sm="12" class="mb-2">
        <div role="group">
          <label for="departure_time" class="label_input_black"
            >Hora de salida</label
          >
          <b-form-input
            id="departure_time"
            size="sm"
            v-model="travel.departure_time"
            disabled
          />
        </div>
      </b-col>
      <b-col lg="6" md="6" sm="12" class="mb-2">
        <div role="group">
          <label for="co_pilo2" class="label_input_black">Precio base</label>
          <b-form-input
            id="co_pilo2"
            size="sm"
            v-model="travel.price"
            disabled
          />
        </div>
      </b-col>
      <b-col lg="6" md="6" sm="12" class="mb-2">
        <div role="group">
          <label for="pilot" class="label_input_black">Piloto</label>
          <b-form-input id="pilot" size="sm" v-model="travel.pilot" disabled />
        </div>
      </b-col>
      <b-col lg="6" md="6" sm="12" class="mb-2">
        <div role="group">
          <label for="co_pilo" class="label_input_black">Copiloto1</label>
          <b-form-input
            id="co_pilo"
            size="sm"
            v-model="travel.co_pilot"
            disabled
          />
        </div>
      </b-col>
      <b-col lg="6" md="6" sm="12" class="mb-2">
        <div role="group">
          <label for="co_pilo2" class="label_input_black">Copiloto2</label>
          <b-form-input
            id="co_pilo2"
            size="sm"
            v-model="travel.co_pilot2"
            disabled
          />
        </div>
      </b-col>
      <b-col lg="6" md="6" sm="12" class="mb-2">
        <div role="group">
          <label for="total" class="label_input_black">Link registro</label>
          <b-form-input :value="link_register" id="link-register" readonly />
        </div>
      </b-col>
      <b-col lg="6" md="6" sm="12" class="mb-2" v-if="getUser.number_sales">
        <div role="group">
          <label for="numberSales" class="label_input_black"
            >Número de ventas permitidas</label
          >
          <b-form-input
            id="numberSales"
            size="sm"
            :value="getUser.number_sales"
            disabled
          />
        </div>
      </b-col>
    </b-row>
    <b-row class="mt-3">
      <b-col lg="12" md="12" sm="12" class="mb-2">
        <div role="group">
          <label for="departure_date" class="label_input_black"
            >Comparte este link con tus clientes para que llenen los datos de
            los pasajeros. Dale click para copiar.</label
          >
        </div>
      </b-col>
      <b-col lg="12" md="12" sm="12" class="mb-2 text-center">
        <div class="link-shared" @click.prevent="copyLink">
          {{ link_register }}
        </div>
      </b-col>
    </b-row>

    <base-modal :title="title" :id="id">
      <template v-slot:form>
        <form-travel
          :record="record"
          :isEdit="isEdit"
          :isDisabled="isDisabled"
          :isTourRealized="isTourRealized"
          @save="() => $emit('refresh')"
        />
      </template>
    </base-modal>
  </div>
</template>

<script>
import {
  BCol,
  BRow,
  BFormInput,
  BButton,
  BIconPencilSquare,
  BIconTrashFill,
} from "bootstrap-vue";
import BaseModal from "@/components/base/BaseModal";
import FormTravel from "../../travel/components/FormTravel";
import { mapGetters } from "vuex";
import PermissionAction from "@/mixins/PermissionAction";
import TravelMixin from "@/mixins/TravelMixin";
import ButtonTutotial from "@/components/buttons/ButtonTutotial";

export default {
  mixins: [PermissionAction, TravelMixin],
  components: {
    BCol,
    BRow,
    BFormInput,
    BButton,
    BIconPencilSquare,
    BIconTrashFill,
    BaseModal,
    FormTravel,
    ButtonTutotial,
  },
  props: {
    travel: {
      type: Object,
      default: null,
    },
    isTourRealized: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      link_register: null,
      isCall: false
    };
  },
  computed: {
    ...mapGetters("user", ["getUser", "getEnterprise"]),
  },
  methods: {
    formatPrice(price) {
      const formatterPeso = new Intl.NumberFormat("es-CO", {
        style: "currency",
        currency: "COP",
        minimumFractionDigits: 0,
      });

      return formatterPeso.format(parseFloat(price));
    },
    copyLink() {
      const me = this;
      const input = document.getElementById("link-register");
      input.select();
      const copy = document.execCommand("copy");
      if (copy) {
        me.messageSuccess('¡Copiado!', 'Link copiado');
      }
    },
  },
  mounted() {
    const me = this;
    const { origin } = window.location;
    me.link_register = `${origin}/registro-de-pasajeros?name=${me.getEnterprise.name
      .split(" ")
      .join("-")
      .normalize("NFD")
      .replace(/[\u0300-\u036f]/g, "")
      .toLowerCase()}&uuid=${me.getEnterprise.uuid}`;
  },
};
</script>

<style lang="css">
.link-shared {
  border-radius: 6px;
  padding: 5px;
  color: var(--black-color);
  font-weight: 700;
  font-size: 20px;
  background-color: var(--fourth-color);
  cursor: pointer;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
}
</style>
