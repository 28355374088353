<template>
  <div>
    <b-container fluid>
      <button-back />
      <b-row>
        <b-col cols="12">
          <h3>Tiquetes</h3>
          <h6>
            <ButtonTutotial :module="module" />
          </h6>
        </b-col>
      </b-row>
      <b-row class="mb-2" v-if="isBusy2">
        <b-col lg="12" md="12" sm="12">
          <div class="text-center text-success my-2">
            <b-spinner class="align-middle mr-1" />
            <strong>Cargando datos...</strong>
          </div>
        </b-col>
      </b-row>
      <section v-else>
        <b-row>
          <b-col cols="12">
            <b-card no-body class="card-global overflow-hidden">
              <b-row>
                <b-col lg="6" md="12" sm="12" class="mb-2">
                  <detail-travel
                    :travel="travel"
                    :isTourRealized="isTourRealized"
                    @refresh="loadTravel"
                  />
                </b-col>
                <b-col lg="6" md="12" sm="12" class="mb-2">
                  <Transportation
                    :travel="travel"
                    :rows="rows"
                    :selectedChairs="selectedChairs"
                    @saleChairs="saleChairs"
                    :chairs="chairs"
                    :isTourRealized="isTourRealized"
                    :isOccupied="isOccupied"
                  />
                </b-col>
              </b-row>
            </b-card>
          </b-col>
        </b-row>
        <b-row class="mt-2">
          <b-col cols="12" class="mt-2">
            <h3>Ventas/Reservas</h3>
            <ButtonTutotial :module="module" />
          </b-col>
          <b-col cols="12">
            <location-table
              :isBusy="isBusy"
              :isTourRealized="isTourRealized"
              :records="records"
              :typeTransport="typeTransport"
              @listPayments="listPayments"
              @detailSale="handleDetail"
              @editSale="handleEditing"
              @deleteSale="handleDeleting"
              @moveSale="moveSale"
              @moveSaleEnterprise="moveSaleEnterprise"
              @updateChairSale="loadSales"
              @updateChairSale2="updateChairSale2"
              @updateSales="updateSales"
            />
          </b-col>
        </b-row>
        <b-row class="mt-2">
          <b-col cols="12" class="mt-2">
            <h3>Totales</h3>
            <ButtonTutotial :module="'7d815776-cb9a-43d7-954f-f9e6b96d80fa'" />
          </b-col>
          <b-col cols="12">
            <totals
            :chairSaleAndTotal="chairSaleAndTotal"
            :chairSaleOther="chairSaleOther"
            :paymentsMade="paymentsMade"
            :totalPaymentsMade="totalPaymentsMade"
            :totalDebt="totalDebt"
            :totalPaymentExtra="totalPaymentExtra"
            :profileUuid="profileUuid"
            />
          </b-col>
        </b-row>  
      </section>
    </b-container>
    <base-modal :title="title" id="modal-sale-ticket">
      <template v-slot:form>
        <form-sale-ticket
          :userUuid="userUuid"
          :chair="chair"
          :selectedChairs="selectedChairs"
          :isBooking="isBooking"
          :travel="travel"
          @save="saveReload"
          @chairOccupied="chairOccupied"
        />
      </template>
    </base-modal>
    <base-modal :title="title" id="modal-edit-detail-ticket">
      <template v-slot:form>
        <form-sale-ticket-edit-detail
          :userUuid="userUuid"
          :record="record"
          :isDisabled="isDisabled"
          :isNotEdit="isNotEdit"
          @save="saveReload"
        />
      </template>
    </base-modal>
    <base-modal title="Pagos" id="modal-payment">
      <template v-slot:form>
        <Payment :payment="payment" @loadSales="loadSales" />
      </template>
    </base-modal>
    <base-modal title="Mover ventas" id="move-sale-modal">
      <template v-slot:form>
        <form-move-sale
          :moveSales="moveSales"
          :travel="travel"
          :isMessageMoved="isMessageMoved"
          :isBooking2="isBooking2"
          @saleUpdate="saleUpdate"
        />
      </template>
    </base-modal>
    <base-modal
      title="Mover ventas a otra empresa"
      id="move-sale-enterprise-modal"
    >
      <template v-slot:form>
        <form-move-sale-enterprise-not-exist
          :moveSales="moveSales"
          :isMessageMoved="isMessageMoved"
          :isBooking2="isBooking2"
          @saleUpdate="saleUpdate"
          @moveSaleEnterpriseNotExist="moveSaleEnterpriseNotExist"
          v-if="isEnterpriseNotExist"
        />
        <form-move-sale-enterprise
          v-else
          :moveSales="moveSales"
          :travel="travel"
          :isMessageMoved="isMessageMoved"
          :isBooking2="isBooking2"
          :isAccompanist="isAccompanist"
          @saleUpdate="saleUpdate"
          @moveSaleEnterpriseNotExist="moveSaleEnterpriseNotExist"
        />
      </template>
    </base-modal>
  </div>
</template>

<script>
import PermissionAction from "@/mixins/PermissionAction";
import ActionCRUD from "@/mixins/ActionCRUD";
import { mapGetters } from "vuex";
//Components
import {
  BContainer,
  BCol,
  BRow,
  VBTooltip,
  BCard,
  BSpinner,
} from "bootstrap-vue";
import BaseModal from "@/components/base/BaseModal";
import DetailTravel from "./components/DetailTravel";
import Transportation from "./components/Transportation";
import FormSaleTicket from "./components/FormSaleTicket";
import Payment from "./components/Payment";
import LocationTable from "./components/LocationTable";
import FormMoveSale from "./components/FormMoveSale";
import FormMoveSaleEnterprise from "./components/FormMoveSaleEnterprise";
import FormSaleTicketEditDetail from "./components/FormSaleTicketEditDetail";
import ButtonBack from "@/components/buttons/ButtonBack";
import FormMoveSaleEnterpriseNotExist from "./components/FormMoveSaleEnterpriseNotExist";
import Totals from "./components/Totals";
import ButtonTutotial from "@/components/buttons/ButtonTutotial";

export default {
  inject: ["travelRepository", "saleRepository"],
  mixins: [PermissionAction, ActionCRUD],
  components: {
    BContainer,
    BCol,
    BRow,
    BCard,
    BSpinner,
    BaseModal,
    DetailTravel,
    Transportation,
    FormSaleTicket,
    Payment,
    LocationTable,
    FormMoveSale,
    FormMoveSaleEnterprise,
    FormMoveSaleEnterpriseNotExist,
    FormSaleTicketEditDetail,
    ButtonBack,
    Totals,
    ButtonTutotial,
  },
  directives: {
    "b-tooltip": VBTooltip,
  },
  data() {
    return {
      currentDate: new Date().toLocaleDateString("fr-CA", {
        timeZone: "America/Bogota",
      }),
      travelUuid: this.$route.params.travelUuid,
      repository: "saleRepository",
      title: "",
      module: "0cea77ef-98ac-4fc5-980f-bca65e552359",
      travel: null,
      chair: null,
      payment: null,
      record: null,
      userUuid: null,
      chairs: null,
      typeTransport: null,
      records: [],
      sales: [],
      sales2: [],
      rows: [],
      moveSales: [],
      selectedChairs: [],
      chairSaleAndTotal: [],
      chairSaleOther: [],
      paymentsMade: [],
      isBusy: false,
      isBusy2: true,
      isDisabled: false,
      isTourRealized: false,
      isBooking: false,
      isBooking2: false,
      isMessageMoved: false,
      isOccupied: false,
      isNotEdit: false,
      isAccompanist: false,
      isTravel: true,
      isEnterpriseNotExist: false,
      totalPaymentsMade: 0,
      totalDebt: 0,
      totalPaymentExtra: 0,
      profileUuid: null
    };
  },
  computed: {
    ...mapGetters("user", ["getUser", "getEnterprise"]),
  },
  async mounted() {
    const me = this;
    me.userUuid = me.getUser.uuid;
    me.profileUuid = me.getUser.profile.uuid;
    await me.loadSales();
    await me.loadTravel();
  },
  methods: {
    async loadTravel() {
      const me = this;
      me.isBusy2 = true;
      try {
        const { data } = await me.travelRepository.find(me.travelUuid);
        me.isTourRealized = data.departure_date < me.currentDate ? true : false;
        me.occupiedChairs(data.transportation.row);
        me.travel = data;

        me.typeTransport = me.travel?.transportation?.typeTransportation?.uuid;
      } catch (error) {
        const { status } = error.data;
        if (status == 419) {
          me.$bvModal.show("expired-token-model");
        } else if (status == 403) {
          me.$bvModal.show("session-finish-modal");
        }
      } finally {
        me.isBusy2 = false;
      }
    },
    saleChairs(chairs, type) {
      const me = this;
      me.selectedChairs = [];
      me.isBooking = type;
      me.isOccupied = false;

      me.title = type ? "Reservar tiquete(s)" : "Vender tiquete(s)";
      me.selectedChairs = chairs;
      me.isDisabled = false;
      me.record = null;
      me.$bvModal.show("modal-sale-ticket");
    },
    occupiedChairs(row) {
      const me = this;
      const sales = [];
      const arraySales = me.profileUuid == "mam783bb-275c-21m4-912b-24c3d0m547e2" ? me.sales2 : me.sales;

      row.forEach((el) => {
        el.chair.forEach((chair) => {
          arraySales.forEach((sale) => {
            sale.chairSale.forEach((sal) => {
              if (sal.status == "Activo") {
                if (sal.chairUuid == chair.uuid) {
                  const sale2 = sales.find((x) => x.uuid === sal.uuid);
                  if (sal.typeSale == "1") {
                    chair.occupancyStatus = true;
                  } else {
                    if (!sale2) {
                      if (sal.typeSale == "2") {
                        chair.transport1 = true;
                      } else {
                        chair.transport2 = true;
                      }
                    }
                  }
                }
              }
            });
          });
        });
      });

      row.sort((a, b) => {
        if (parseInt(a.number_row) > parseInt(b.number_row)) {
          return 1;
        }
        if (parseInt(a.number_row) < parseInt(b.number_row)) {
          return -1;
        }
        return 0;
      });

      row.map((el) => {
        el.chair.sort((a, b) => {
          if (parseInt(a.number_chair) > parseInt(b.number_chair)) {
            return 1;
          }
          if (parseInt(a.number_chair) < parseInt(b.number_chair)) {
            return -1;
          }
          return 0;
        });
      });

      me.rows = row;

      const occupieds = [];
      const totalChairs = [];
      const transport1 = [];
      const transport2 = [];
      const transport3 = [];

      me.rows.forEach((el) => {
        el.chair.forEach((chair) => {
          totalChairs.push(chair);
          if (chair.occupancyStatus) {
            occupieds.push(chair);
          } else if (chair.transport2 && chair.transport1) {
            transport3.push(chair);
          } else if (chair.transport1) {
            transport1.push(chair);
          } else if (chair.transport2) {
            transport2.push(chair);
          }
        });
      });

      me.chairs = {
        occupied: occupieds.length,
        available:
          totalChairs.length -
          (occupieds.length + transport1.length + transport3.length),
        oneWay: transport1.length,
        justCameBack: transport2.length,
        roundTrip: transport3.length,
      };
    },
    handleDetail(item) {
      const me = this;
      me.title = "Detalle de venta ticket";
      me.isDisabled = true;
      me.isNotEdit = false;
      delete item.client.uuid;
      const client = item.client;
      me.record = { ...me.record, ...item, ...client };
      delete me.record.client;
      me.$bvModal.show("modal-edit-detail-ticket");
    },
    handleEditing(item) {
      const me = this;
      me.title = "Actualizar venta ticket";
      delete item.client.uuid;
      const client = item.client;
      me.record = { ...me.record, ...item, ...client };
      if (isNaN(Number(me.record.identification))) {
        me.isNotEdit = true;
        me.isDisabled = true;
      } else {
        me.isNotEdit = false;
        me.isDisabled = false;
      }

      me.$bvModal.show("modal-edit-detail-ticket");
      delete me.record.client;
    },
    async handleDeleting(uuid) {
      const me = this;
      me.$bvModal
        .msgBoxConfirm("¿Seguro que deseas eliminar este registro?", {
          title: "Confirmar eliminación",
          size: "sm",
          buttonSize: "sm",
          okVariant: "danger",
          okTitle: "Si",
          cancelTitle: "No",
          footerClass: "p-2",
          hideHeaderClose: false,
          centered: true,
        })
        .then(async (value) => {
          if (value) {
            try {
              await me.saleRepository.delete(uuid);

              me.messageSuccess('Registro eliminado', 'Registro eliminado con exito');
              await me.loadSales();
              await me.loadTravel();
            } catch (error) {
              const { status, message } = error.data;
              if (status == 419) {
                me.$bvModal.show("expired-token-model");
              } else if (status == 403) {
                me.$bvModal.show("session-finish-modal");
              }
              me.errorSave(message)
            }
          }
        });
    },
    async saveReload(id) {
      const me = this;
      me.selectedChairs = [];
      me.messageSuccess();
      me.$bvModal.hide(id);
      await me.loadSales();
      me.loadTravel();
    },
    listPayments(item) {
      const me = this;
      me.payment = {
        saleUuid: item?.principalSaleUuid ? item.principalSaleUuid : item.uuid,
        principalSaleUuid: item?.principalSaleUuid ? item.principalSaleUuid : '',
        saleUuidSecond: item?.uuid,
        statusSale: item?.status
      };
      me.title = "Pagos del ticket";
      me.$bvModal.show("modal-payment");
    },
    async loadSales() {
      const me = this;
      me.records = [];
      try {
        me.isBusy = true;
        const profileUuid = me.profileUuid == "mam783bb-275c-21m4-912b-24c3d0m547e2" ? me.getUser.profile.uuid  : null;

        const { data } = await me.saleRepository.getAll(me.travelUuid, profileUuid);

        if (profileUuid == "mam783bb-275c-21m4-912b-24c3d0m547e2") {
          const { data: data2 } = await me.saleRepository.getAll(me.travelUuid, null);
          me.sales2 = data2;
        }


        data.forEach((el) => {
          el.isDelete = el.chairSale.find((x) => x.status !== "Activo") ? false : true;
          el.chairSale.sort((a, b) => {
            if (
              parseInt(a.chair.number_chair) > parseInt(b.chair.number_chair)
            ) {
              return 1;
            }
            if (
              parseInt(a.chair.number_chair) < parseInt(b.chair.number_chair)
            ) {
              return -1;
            }
            return 0;
          });
        });

        me.records = data;
        me.sales = data;
        me.totalSale();
      } catch (error) {
        console.log(error)
        const { status } = error.data;
        if (status == 419) {
          me.$bvModal.show("expired-token-model");
        } else if (status == 403) {
          me.$bvModal.show("session-finish-modal");
        }
      } finally {
        me.isBusy = false;
      }
    },
    moveSale(items) {
      const me = this;
      me.moveSales = [];
      me.isBooking2 = false;
      me.isMessageMoved = false;
      let type1 = [];
      let type2 = [];
      let type3 = [];
      let booking = [];
      //Verificar que sean del mismo tipo de venta.
      items.forEach((el) => {
        if (el.sale.is_deb) booking.push(el);
        el.selecteds.forEach((x) => {
          if (x.typeSale == "1") {
            //Tour (1)
            type1.push(x);
          } else if (x.typeSale == "2") {
            //Solo ida (2)
            type2.push(x);
          } else {
            //Solo regreso (2)
            type3.push(x);
          }
        });
      });

      if (
        (type1.length > 0 && type2.length > 0) ||
        (type1.length > 0 && type3.length > 0) ||
        (type2.length > 0 && type3.length > 0)
      ) {
        me.isMessageMoved = true;
      }

      if (booking.length > 0) {
        me.isMessageMoved = true;
        me.isBooking2 = true;
      }

      me.moveSales = items;

      me.$bvModal.show("move-sale-modal");
    },
    moveSaleEnterprise(items) {
      const me = this;
      me.moveSales = [];
      me.isBooking2 = false;
      me.isAccompanist = false;
      me.isMessageMoved = false;
      let type1 = [];
      let type2 = [];
      let type3 = [];
      let booking = [];
      let accompanist = [];

      //Verificar que sean del mismo tipo de venta.
      items.forEach((el) => {
        if (el.sale.is_deb) booking.push(el);
        el.selecteds.forEach((x) => {
          if (x.typeSale == "1") {
            //Tour (1)
            type1.push(x);
          } else if (x.typeSale == "2") {
            //Solo ida (2)
            type2.push(x);
          } else {
            //Solo regreso (2)
            type3.push(x);
          }
        });
      });

      if (
        (type1.length > 0 && type2.length > 0) ||
        (type1.length > 0 && type3.length > 0) ||
        (type2.length > 0 && type3.length > 0)
      ) {
        me.isMessageMoved = true;
      }

      if (booking.length > 0) {
        me.isMessageMoved = true;
        me.isBooking2 = true;
      }

      if (accompanist.length > 0) {
        me.isAccompanist = true;
        me.isMessageMoved = true;
      }

      me.moveSales = items;
      me.$bvModal.show("move-sale-enterprise-modal");
    },
    async saleUpdate(message, idModal) {
      const me = this;

      if (message) {
        const { title, messageErr } = message;
        me.messageSuccess(title, messageErr);
      }
      me.$bvModal.hide(idModal);
      me.updateChairSale2()
    },
    async chairOccupied(message) {
      const me = this;
      me.selectedChairs = [];
      me.isOccupied = true;
      let title = "Error al guardar registro";
      let messageErr = "Registro no guardado.";

      if (message === "Chair is occupied") {
        title = "Error Silla(s) ocupada(s)";
        messageErr = "La(s) silla(s) se encuentra(n) ocupada(S)";
        await me.loadSales();
        me.loadTravel();
        me.$bvModal.hide("modal-sale-ticket");
      }

      me.messageError(title, messageErr, "icon-message-error");
    },
    totalSale() {
      const me = this;
      me.chairSaleAndTotal = []; //Vendidas por tipo de venta
      me.chairSaleOther = []; //Eliminadas y movidas
      me.paymentsMade = []; // Pagos realizados
      me.totalPaymentsMade = 0; 
      me.totalDebt = 0;
      me.totalPaymentExtra = 0;
      me.sales?.forEach((el) => {
        el.chairSale.forEach((x) => {
          //Sacar el numero de sillas vendidas por tipo de venta
          const record = me.chairSaleAndTotal.find(
            (y) => y.name === x.typeSale
          )

          if (record) {
            record.totals.push(Number(x.sale_price))
            me.chairSaleAndTotal[me.chairSaleAndTotal.indexOf(record)] = record
          } else {
            me.chairSaleAndTotal.push({
              name: x.typeSale,
              totals: [Number(x.sale_price)],
              total: 0,
            })
          }
          // Sacar el numero de silla, movidas y eliminadas
          let price = -Number(x.sale_price);

          if (x.status == "moveTourEnterprise") {
            price = -Number(x.sale_price_move_enterprise);
          } else if (x.status == "deleteTicketWithDevol" && (x.sale_price_devolution && x.sale_price_devolution !== "0")) {
            price = -Number(x.sale_price_devolution)
          }
          const record2 = me.chairSaleOther.find(
            (y) => y.name === x.status
          )

          if (record2) {
            record2.totals.push(price)
            me.chairSaleOther[me.chairSaleOther.indexOf(record2)] = record2
          } else if (x.status !== "Activo" && x.status !== "deleteTicket") {
            me.chairSaleOther.push({
              name: x.status,
              totals: [price],
              total: 0
            });
          } else if (el.is_deb && el.status == "Cancelado" && x.status == "deleteTicket") {
            me.chairSaleOther.push({
              name: x.status,
              totals: [price],
              total: 0
            });
          }
        });

        //Payments
        el.payment.sort((a, b) => {
          if (a.createdAt < b.createdAt) {
            return 1;
          }
          if (a.createdAt > b.createdAt) {
            return -1;
          }
          return 0;
        });

        if (el.status == "Activo") me.totalDebt += el.payment.length > 0 ? Number(el.payment[0]?.debt) : 0;

        el.payment.forEach((x) => {
          const record = me.paymentsMade.find((y) => y.type === x.type && y.name === x.payment_method)
          if (record) {
            record.totals.push(Number(x.payment_price))
            me.paymentsMade[me.paymentsMade.indexOf(record)] = record
          } else if (x.type == "s" && x.payment_method !== "No realizó abono") {
            me.paymentsMade.push({
              type: x.type,
              totals: [Number(x.payment_price)],
              name: x.payment_method,
              total: 0,
            });
          }
        });

        //Payment extra
        if (!el.is_deb && el.principalSaleUuid) {
          me.totalPaymentExtra = el.chairSale.reduce((a, b) => a + Number(b.sale_price), 0);
        }
      });

      // Calcular el total de las ventas
      me.chairSaleAndTotal.sort((a, b) => Number(a.name) - Number(b.name));
      me.chairSaleAndTotal.forEach((el) => {
        if (el.name == "1") {
          el.name = "Tour";
        } else if (el.name == "2") {
          el.name = "Solo ida";
        } else {
          el.name = "Solo regreso";
        }
        el.total = el.totals.reduce((a, b) => a + b, 0)
      })

      me.chairSaleOther.forEach((el) => {
        if (el.name == "moveTour") {
          el.name = "Tiquetes movidos a otro tour";
        } else if (el.name == "moveTourEnterprise") {
          el.name = "Tiquetes movidos a otra empresa";
        } else if (el.name === "deleteTicketWithDevol") {
          el.name = "Tiquetes eliminados con descuento ó devolucion de dinero";
        } else if (el.name === "deleteTicket") {
          el.name = "Tiquetes de venta/reserva cancelada que no fueron pagados";
        }
        el.total = el.totals.reduce((a, b) => a + b, 0)
      })

      me.paymentsMade.forEach((el) => {
        el.total = el.totals.reduce((a, b) => a + b, 0)
        me.totalPaymentsMade += el.total
      });
    },
    async updateChairSale2() {
      const me = this;
      await me.loadSales();
      me.loadTravel();
    },
    updateSales() {
      const me = this;
      me.messageSuccess('Registro eliminado', 'Registro eliminado con exito');
      me.$bvModal.hide("modal-delete-chair-sale");
      me.updateChairSale2();
    },
    moveSaleEnterpriseNotExist() {
      const me = this;
      me.isEnterpriseNotExist = !me.isEnterpriseNotExist;
    }
  },
};
</script>

<style lang="css">
.title {
  color: var(--black-color);
  font-family: var(--fuente-primary);
  font-size: 20px;
  font-weight: 700;
}
</style>
