<template>
  <b-form @submit.prevent="saveData" ref="form" autocomplete="off">
    <b-row class="mb-2">
      <b-col lg="12" md="12" sm="12">
        <div class="label_input text-white">* Campos requeridos.</div>
      </b-col>
      <b-col lg="12" md="12" sm="12" class="mb-3">
        <div role="group">
          <label for="name" class="label_input">Número de zarpe*</label>
          <b-form-input
            id="name"
            size="sm"
            placeholder="Número de zarpe"
            v-model="formData.numberSarper"
            autofocus
          />
        </div>
      </b-col>
    </b-row>
    <b-row class="footer-modal-customer">
      <b-col cols="12" class="d-flex justify-content-end">
        <b-button
          type="submit"
          size="lg"
          class="btn btn-success"
          :disabled="isBusy || $v.$invalid"
        >
          <b-spinner class="mr-2" v-if="isBusy" small />
          Generar
        </b-button>
      </b-col>
    </b-row>
  </b-form>
</template>

<script>
import {
  BCol,
  BRow,
  BButton,
  BForm,
  BSpinner,
  BFormInput,
} from "bootstrap-vue";
import { required } from "vuelidate/lib/validators";

export default {
  inject: ["planClientRepository"],
  components: {
    BCol,
    BRow,
    BButton,
    BForm,
    BFormInput,
    BSpinner,
  },
  props: {
    record: {
      type: Object,
      default: null,
    },
    isDisable: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      profiles: [],
      isBusy: false,
      formData: {
        numberSarper: '',
      },
      repository: "planClientRepository",
    };
  },
  validations: {
    formData: {
      numberSarper: {
        required,
      },
    },
  },
  methods: {
    saveData() {
      const me = this;
      me.$emit("generarPdf", me.formData);
    },
  },
};
</script>

<style></style>
