<template>
  <b-popover :target="target" triggers="click blur"  placement="right">
    <div class="mb-1">
      <b-button
        variant="secondary"
        class="btn-icon btn_icon w-100 _btn"
        size="lg"
        @click.prevent="() => $emit('listPayments', record)"
      >
        <img
          src="@/assets/images/boleto.png"
          width="25px"
          height="25px"
          class="mr-1"
          alt="Guiia Tour"
        />
        Pagos
      </b-button>
    </div>
    <div class="mb-1">
      <b-button
        v-if="hasPermission(module, 'detail')"
        variant="primary"
        class="btn-icon btn_icon w-100 _btn"
        size="lg"
        @click.prevent="() => $emit('detail', record)"
      >
        <b-icon-eye-fill class="mr-1" />
        Detalles
      </b-button>
    </div>
    <div class="mb-1">
      <b-button
        v-if="hasPermission(module, 'edit')"
        variant="warning"
        class="btn-icon btn_icon w-100 _btn"
        size="lg"
        :disabled="isTourRealized"
        @click.prevent="() => $emit('edit', record)"
      >
        <b-icon-pencil-square class="mr-1" />
        Editar
      </b-button>
    </div>
    <div class="">
      <b-button
        v-if="hasPermission(module, 'delete') && status == 'Activo' && record?.isDelete"
        variant="danger"
        class="btn-icon btn_icon w-100 _btn"
        size="lg"
        :disabled="isTourRealized"
        @click.prevent="() => $emit('delete', record.uuid)"
      >
        <b-icon-trash-fill class="mr-1" />
        Eliminar todo
      </b-button>
    </div>
  </b-popover>
</template>

<script>
import {
  BButton,
  BIconTrashFill,
  BPopover,
  BIconPencilSquare,
  BIconEyeFill,
} from "bootstrap-vue";
import PermissionAction from "@/mixins/PermissionAction";

export default {
  mixins: [PermissionAction],
  components: {
    BButton,
    BIconTrashFill,
    BPopover,
    BIconPencilSquare,
    BIconEyeFill,
  },
  props: {
    target: {
      type: String,
      default: null,
    },
    record: {
      type: Object,
      default: null,
    },
    isTourRealized: {
      type: Boolean,
      default: false,
    },
    module: {
      type: String,
      default: null,
    },
    status: {
      type: String,
      default: null,
    },
  },
  methods: {},
};
</script>
<style scoped>
._btn{
  height: 40px !important;
  font-size: 18px !important;
}
</style>