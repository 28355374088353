<template>
  <b-form @submit.prevent="save" ref="form" autocomplete="off">
    <b-row>
      <b-col lg="6" md="12" sm="12" class="mb-3">
        <div role="group">
          <label for="names" class="label_input">Nombres*</label>
          <b-form-input
            id="names"
            size="sm"
            v-model="formData.names"
            :disabled="isDisabled"
          />
        </div>
      </b-col>
      <b-col lg="6" md="12" sm="12" class="mb-3">
        <div role="group">
          <label for="last_names" class="label_input">Apellidos*</label>
          <b-form-input
            id="last_names"
            size="sm"
            v-model="formData.last_names"
            :disabled="isDisabled"
          />
        </div>
      </b-col>
      <b-col lg="6" md="12" sm="12" class="mb-3">
        <div role="group">
          <label class="label_input">Tipo de identificación*</label>
          <v-select
            :options="typeIndetificatios"
            label="value"
            class="style-chooser"
            placeholder="Seleccione"
            v-model="formData.type_identification"
            :disabled="isDisabled"
          >
            <div slot="no-options">Sin opciones</div>
          </v-select>
        </div>
      </b-col>
      <b-col lg="6" md="12" sm="12" class="mb-3">
        <div role="group">
          <label for="identification" class="label_input"
            >Identificación*</label
          >
          <b-form-input
            id="identification"
            size="sm"
            v-model="$v.formData.identification.$model"
            @blur="$v.formData.identification.$touch()"
            :disabled="isDisabled"
          />
          <span
            v-if="$v.formData.identification.$error"
            class="help-block text-danger text_error"
          >
            {{ messageValidation($v.formData.identification) }}
          </span>
        </div>
      </b-col>
      <b-col lg="6" md="12" sm="12" class="mb-3">
        <div role="group">
          <label for="age" class="label_input">Edad*</label>
          <b-form-input
            id="age"
            size="sm"
            v-model="formData.age"
            :disabled="isDisabled"
          />
        </div>
      </b-col>
      <b-col lg="6" md="12" sm="12" class="mb-3">
        <div role="group">
          <label for="eps" class="label_input">Eps*</label>
          <b-form-input
            id="eps"
            size="sm"
            v-model="formData.eps"
            :disabled="isDisabled"
          />
        </div>
      </b-col>
    </b-row>
    <b-row class="footer-modal-customer" v-if="!isDisabled">
      <b-col cols="12" class="d-flex justify-content-end">
        <b-button
          type="submit"
          size="lg"
          class="btn btn-success w-width"
          :disabled="isBusy || $v.$invalid"
        >
          <b-spinner class="mr-2" v-if="isBusy" small />
          Guardar
        </b-button>
      </b-col>
    </b-row>
  </b-form>
</template>

<script>
import {
  BCol,
  BRow,
  BButton,
  BForm,
  BSpinner,
  BFormInput,
} from "bootstrap-vue";
import vSelect from "vue-select";
import {
  required,
  numeric,
} from "vuelidate/lib/validators";
import ActionCRUD from "@/mixins/ActionCRUD";

export default {
  inject: ["babyRepository"],
  mixins: [ActionCRUD],
  components: {
    BCol,
    BRow,
    BButton,
    BForm,
    BFormInput,
    BSpinner,
    vSelect,
  },
  props: {
    baby: {
      type: Object,
      default: null,
    },
    isDisabled: {
      type: Boolean,
      default: false,
    },
    record: {
      type: Object,
      default: null,
    },
  },
  data() {
    const me = this;
    return {
      isBusy: false,
      formData: {
        names: "",
        last_names: "",
        type_identification: "Registro civil",
        identification: "",
        age: "",
        eps: "",
        accompanistUuid: me.baby.accompanistUuid,
      },
      typeIndetificatios: [
        "Registro civil",
        "Pasaporte",
      ],
    };
  },
  validations: {
    formData: {
      names: {
        required,
      },
      last_names: {
        required,
      },
      type_identification: {
        required,
      },
      identification: {
        required,
        numeric,
      },
      age: {
        required,
      },
      eps: {
        required,
      },
    },
  },
  async mounted() {
    const me = this;
    if (me.record) {
      me.formData = { 
        names: me.record.names,
        last_names: me.record.last_names,
        type_identification: me.record.type_identification,
        identification: me.record.identification,
        age: me.record.age,
        eps: me.record.eps,
        accompanistUuid: me.record.accompanistUuid,
        uuid: me.record.uuid,
      }
    }
  },
  methods: {
    async save() {
      const me = this;
      try {
        me.isBusy = true;
        const uuid = me.formData.uuid ? me.formData.uuid : null;
        const option = uuid ? "update" : "save";

        await me.babyRepository[option](uuid, me.formData);
        me.$emit("save");
      } catch (error) {
        const me = this;
        const { status, message } = error.data;
        if (status == 419) {
          me.$bvModal.show("expired-token-model");
        } else if (status == 403) {
          me.$bvModal.show("session-finish-modal");
        }
      } finally {
        me.isBusy = false;
      }
    },
  },
};
</script>
