<template>
  <b-form @submit.prevent="saveData" ref="form" autocomplete="off">
    <b-row v-if="isMessageMoved">
      <b-col lg="12" md="12" sm="12" class="mb-3">
        <p v-if="isBooking2" class="text__ mb-0">
          No se pueden mover reservas que aún deben dinero.
        </p>
        <p v-else-if="isAccompanist">
          Para poder mover estas ventas a otra empresa, se debe llenar la
          información del pasajero.
        </p>
        <p v-else class="text__ mb-0">
          Sólo se pueden mover ventas, del mísmo tipo (Tour, Solo ida, Solo
          regreso).
        </p>
      </b-col>
    </b-row>
    <template v-else>
      <b-row>
        <b-col lg="12" md="12" sm="12" class="mb-3">
          <p class="text__ mb-2">Si la empresa a donde vas a mover las ventas no esta registrada en la plataforma, da click en este botón.</p>
          <b-button @click.prevent="() => $emit('moveSaleEnterpriseNotExist')">Mover a empresa no registrada</b-button>
        </b-col>
      </b-row>
      <b-row class="mb-3">
        <b-col lg="6" md="12" sm="12" class="mb-3">
          <div role="group">
            <label class="label_input">Empresa</label>
            <v-select
              v-model="formData.enterpriseSelected"
              :options="enterprises"
              :reduce="(option) => option.uuid"
              label="name"
              class="style-chooser"
              placeholder="Seleccione"
              @input="loadTypeTourEnterprises"
            >
              <div slot="no-options">Sin opciones</div>
            </v-select>
          </div>
        </b-col>
        <b-col lg="6" md="12" sm="12" class="mb-3" v-if="formData.enterpriseSelected">
          <date-picker
            v-model="date"
            :attributes="attrs"
            :model-config="modelConfig"
            :available-dates="{ start: new Date(), end: null }"
            @input="loadTravels"
          >
            <template v-slot="{ inputValue, inputEvents }">
              <b-row>
                <b-col lg="12" md="12" sm="12">
                  <div role="group">
                    <label class="label_input">Fecha de salida</label>
                    <b-form-input
                      size="sm"
                      :value="inputValue"
                      v-on="inputEvents"
                      readonly
                      :disabled="formData.enterpriseSelected ? false : true"
                    />
                  </div>
                </b-col>
              </b-row>
            </template>
          </date-picker>
        </b-col>
        <b-col lg="12" md="12" sm="12" class="mb-3">
          <div role="group">
            <label class="label_input">Transporte</label>
            <v-select
              v-model="travelSelected"
              :options="travels"
              label="name"
              class="style-chooser"
              placeholder="Seleccione"
              @input="loadSalesByTravel"
            >
              <div slot="no-options">Sin opciones</div>
            </v-select>
          </div>
        </b-col>
        <b-col lg="6" md="12" sm="12" class="mb-3">
          <div role="group">
            <label class="label_input">Tipo de tour</label>
            <v-select
              v-model="formData.planClientUuid"
              :options="typeTours"
              :reduce="(option) => option.uuid"
              label="name"
              class="style-chooser"
              placeholder="Seleccione"
            >
              <div slot="no-options">Sin opciones</div>
            </v-select>
          </div>
        </b-col>
        <b-col lg="6" md="12" sm="12" class="mb-3">
          <div role="group">
            <label class="label_input">Silla disponibles</label>
            <b-form-input size="sm" disabled v-model="formData.availables" />
          </div>
        </b-col>
        <b-col lg="6" md="12" sm="12" class="mb-3">
          <div role="group">
            <label class="label_input">N° de ventas a mover</label>
            <b-form-input
              size="sm"
              disabled
              :value="cuantityChairSale(formData.moveSales)"
            />
          </div>
        </b-col>
        <b-col lg="6" md="12" sm="12" class="mb-3">
          <div role="group">
            <label for="payment_method" class="label_input"
              >Método de pago*</label
            >
            <v-select
              :options="typeOfPayments"
              :reduce="(option) => option.id"
              label="name"
              class="style-chooser"
              placeholder="Seleccione"
              v-model="formData.payment_method"
            >
              <div slot="no-options">Sin opciones</div>
            </v-select>
          </div>
        </b-col>
        <b-col lg="6" md="12" sm="12" class="mb-3">
          <div role="group">
            <label for="sale_price" class="label_input"
              >Precio de venta unitario*</label
            >
            <b-form-input
              id="sale_price"
              size="sm"
              v-model="formData.sale_price"
              @keyup="calculateTotal"
              :disabled="formData.chairSales.length > 0 ? false : true"
            />
          </div>
        </b-col>
        <b-col lg="6" md="12" sm="12" class="mb-3">
          <div role="group">
            <label for="total" class="label_input">Precio total</label>
            <b-form-input
              id="total"
              size="sm"
              v-model="formData.total"
              disabled
            />
          </div>
        </b-col>
        <b-col
          lg="12"
          md="12"
          sm="12"
          class="mb-3"
          v-if="isDisabled2 || isNotChairs"
        >
          <div class="panel__msg--error">
            <small class="msg__error mt-1">
              {{ messageError2() }}
            </small>
          </div>
        </b-col>
      </b-row>
      <b-row class="footer-modal-customer" v-if="!isDisabled2">
        <b-col cols="12" class="d-flex justify-content-end">
          <b-button
            type="submit"
            size="lg"
            class="btn btn-success w-width"
            :disabled="isBusy || $v.$invalid"
          >
            <b-spinner class="mr-2" v-if="isBusy" small />
            Guardar
          </b-button>
        </b-col>
      </b-row>
    </template>
  </b-form>
</template>

<script>
import {
  BCol,
  BRow,
  BButton,
  BForm,
  BSpinner,
  BFormInput,
} from "bootstrap-vue";
import vSelect from "vue-select";
import DatePicker from "v-calendar/lib/components/date-picker.umd";
import { required } from "vuelidate/lib/validators";
import { mapGetters } from "vuex";
import typePayments from "@/data/typePayments.json";
import { formatNumber } from "@/utils";
export default {
  inject: [
    "travelRepository",
    "saleRepository",
    "enterpriseRepository",
    "planClientRepository",
  ],
  components: {
    BCol,
    BRow,
    BButton,
    BForm,
    BFormInput,
    BSpinner,
    vSelect,
    DatePicker,
  },
  props: {
    moveSales: {
      type: Array,
      default: null,
    },
    travel: {
      type: Object,
      default: null,
    },
    isMessageMoved: {
      type: Boolean,
      default: false,
    },
    isBooking2: {
      type: Boolean,
      default: false,
    },
    isAccompanist: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    const me = this;
    return {
      travelUuid: me.$route.params.travelUuid,
      isBusy: false,
      isDisabled: false,
      isDisabled2: false,
      formData: {
        travelUuid: null,
        travelUuid2: me.$route.params.travelUuid,
        planClientUuid: null,
        availables: null,
        availables2: [],
        moveSales: me.moveSales,
        chairSales: [],
        typeSale: null,
        total: null,
        sale_price: null,
        enterpriseSelected: null,
        payment_method: null,
      },
      travels: [],
      chairAvailable: [],
      occupieds: [],
      enterprises: [],
      typeTours: [],
      travelSelected: null,
      modelConfig: {
        type: "string",
        mask: "YYYY-MM-DD",
      },
      attrs: [
        {
          highlight: "orange",
          dates: new Date(),
        },
      ],
      date: "",
      isNotChairs: false,
      typeOfPayments: typePayments,
    };
  },
  computed: {
    ...mapGetters("user", ["getEnterprise"]),
  },
  validations: {
    formData: {
      travelUuid: {
        required,
      },
      availables: {
        required,
      },
      moveSales: {
        required,
      },
      planClientUuid: {
        required,
      },
      typeSale: {
        required,
      },
      total: {
        required,
      },
      sale_price: {
        required,
      },
      enterpriseSelected: {
        required,
      },
      payment_method: {
        required,
      },
    },
  },
  async mounted() {
    const me = this;
    me.loadEnterprises();
  },
  methods: {
    cuantityChairSale(moveSales) {
      const chairSales = [];

      moveSales.forEach((el) => {
        el.selecteds.forEach((x) => {
          chairSales.push(x);
        });
      });

      return chairSales.length;
    },
    async loadTravels() {
      const me = this;
      me.isNotChairs = false;
      const typeTransportationUuid = me.travel.transportation.typeTransportation.uuid;
      let isTransportation =
        typeTransportationUuid == "5fa3b241-b713-473b-b412-9b889a456dc5"
          ? true
          : false;
      me.travels = [];
      me.formData.availables = null;
      me.formData.availables2 = [];
      me.travelSelected = null;
      me.formData.travelUuid = null;
      try {
        const { data } = await me.travelRepository.getAllTravelEnterpriseByDate(
          me.formData.enterpriseSelected,
          me.travelUuid,
          me.date,
          "eq"
        );
        data.forEach((el) => {
          if (el.uuid != me.travelUuid) {
            if (
              isTransportation &&
              el.transportation.typeTransportation.uuid ==
                "5fa3b241-b713-473b-b412-9b889a456dc5"
            ) {
              me.travels.push({
                uuid: el.uuid,
                name:
                  el.transportation.typeTransportation.name +
                  " " +
                  el.transportation.name +
                  " " +
                  "capacidad: " +
                  el.transportation.capacity,
                rows: el.transportation.row,
                capacity: el.transportation.capacity,
              });
            } else if (
              !isTransportation &&
              el.transportation.typeTransportation.uuid !==
                "5fa3b241-b713-473b-b412-9b889a456dc5"
            ) {
              me.travels.push({
                uuid: el.uuid,
                name:
                  el.transportation.typeTransportation.name +
                  " " +
                  el.transportation.name +
                  " " +
                  "capacidad: " +
                  el.transportation.capacity,
                rows: el.transportation.row,
                capacity: el.transportation.capacity,
              });
            }
          }
        });
      } catch (error) {
        const { status } = error.data;
        if (status == 419) {
          me.$bvModal.show("expired-token-model");
        } else if (status == 403) {
          me.$bvModal.show("session-finish-modal");
        }
      }
    },
    async loadEnterprises() {
      const me = this;
      try {
        const { data } = await me.enterpriseRepository.getAll();
        data.forEach((el) => {
          if (el.status == "Activo" && el.uuid !== me.getEnterprise.uuid) {
            me.enterprises.push({
              uuid: el.uuid,
              name: el.name,
            });
          }
        });
      } catch (error) {
        const { status } = error.data;
        if (status == 419) me.$bvModal.show("expired-token-model");
      }
    },
    async loadTypeTourEnterprises(uuid) {
      const me = this;
      me.typeTours = [];
      try {
        const { data } = await me.planClientRepository.getAllByOtherEnterprise(uuid);
        data.forEach((el) => {
          me.typeTours.push({
            uuid: el.uuid,
            name: el.name,
          });
        });
      } catch (error) {
        const { status } = error.data;
        if (status == 419) {
          me.$bvModal.show("expired-token-model");
        } else if (status == 403) {
          me.$bvModal.show("session-finish-modal");
        }
      }
    },
    async loadSalesByTravel() {
      const me = this;
      if (me.travelSelected.rows.length > 0) {
        try {
          me.formData.availables = null;
          me.formData.chairSales = [];
          me.formData.availables2 = [];
          me.formData.travelUuid = me.travelSelected.uuid;
          const { data } = await me.saleRepository.getAll(
            me.travelSelected.uuid
          );

          me.formData.typeSale = me.moveSales[0].selecteds[0].typeSale;
          me.moveSales.forEach((el) => {
            el.selecteds.forEach((x) => {
              me.formData.chairSales.push(x);
            });
          });

          if (data.length > 0) {
            me.occupiedChairs(data);
          } else {
            me.travelSelected.rows.forEach((el) => {
              el.chair.forEach((chair) => {
                me.formData.availables2.push(chair);
              });
            });
            me.formData.availables = me.travelSelected.capacity;
          }
        } catch (error) {
          const { status } = error.data;
          if (status == 419) {
            me.$bvModal.show("expired-token-model");
          } else if (status == 403) {
            me.$bvModal.show("session-finish-modal");
          }
        }
      } else {
        me.isNotChairs = true;
      }
    },
    occupiedChairs(sales) {
      const me = this;

      me.travelSelected.rows.forEach((el) => {
        el.chair.forEach((chair) => {
          sales.forEach((chairSal) => {
            chairSal.chairSale.forEach((x) => {
              if (me.formData.typeSale == "1") {
                if (x.chairUuid == chair.uuid && x.status === "Activo") {
                  chair.occupancyStatus = true;
                }
              } else {
                if (
                  (x.chairUuid == chair.uuid && x.typeSale == "1") ||
                  (x.chairUuid == chair.uuid &&
                    x.typeSale == me.formData.typeSale)
                  && x.status === "Activo"
                ) {
                  chair.occupancyStatus = true;
                }
              }
            });
          });
        });
      });

      const availables = [];

      me.travelSelected.rows.forEach((el) => {
        el.chair.forEach((chair) => {
          if (!chair.occupancyStatus) {
            availables.push(chair);
          }
        });
      });

      me.formData.availables = availables.length;
      me.formData.availables2 = availables;

      me.isDisabled2 =
        availables.length < me.formData.chairSales.length ? true : false;
    },
    async saveData() {
      const me = this;
      me.$bvModal
        .msgBoxConfirm(
          "¿Seguro que deseas realizar el movimiento de las ventas?",
          {
            title: "Confirmar movimiento",
            size: "sm",
            buttonSize: "sm",
            okVariant: "danger",
            okTitle: "Si",
            cancelTitle: "No",
            footerClass: "p-2",
            hideHeaderClose: false,
            centered: true,
          }
        )
        .then(async (value) => {
          if (value) {
            let title, messageErr;
            try {
              me.isBusy = true;
              await me.saleRepository.moveSaleEnteprise(me.formData);
              title = "Ventas movidas";
              messageErr = "Ventas movidas con exito.";
              me.$emit(
                "saleUpdate",
                { title, messageErr },
                "move-sale-enterprise-modal"
              );
            } catch (error) {
              const me = this;
              const { status, message } = error.data;
              if (status == 419) {
                me.$bvModal.show("expired-token-model");
              } else if (status == 403) {
                me.$bvModal.show("session-finish-modal");
              }

              title = "Error al guardar registro";
              messageErr = "Registro no guardado.";

              if (message == "Chair is occupied") {
                title = "Error silla(s) ocupada(s)";
                messageErr = "No fue posible mover todas las ventas.";
                me.$emit("saleUpdate", null);
              } 
              me.messageError(title, messageErr);
            } finally {
              me.isBusy = false;
            }
          }
        });
    },
    messageError2() {
      const me = this;
      let message = null;

      if (me.isNotChairs) {
        message = "El transporte seleccionado no tiene sillas agregadas.";
      } else {
        message = `Las sillas disponibles de este transporte no cumplen con la cantidad de
          ventas que deseas mover. Por favor escoge otra transporte o selecciona
          un número menor de ventas a mover.`;
      }
      return message;
    },
    formatPrice(price) {
      const me = this;
      let price2 = price.split(".")
        ? Number(price.split(".").join(""))
        : Number(price);

      const total = me.formData.chairSales.length * price2;

      const formatterPeso = new Intl.NumberFormat("es-CO", {
        minimumFractionDigits: 0,
      });

      return formatterPeso.format(total);
    },
    calculateTotal() {
      const me = this;
      me.formData.sale_price = formatNumber(me.formData.sale_price);
      me.formData.total = me.formatPrice(me.formData.sale_price);
    },
  },
};
</script>

<style lang="css">
.box_number_ {
  border: 1px solid;
  padding: 3px;
  font-size: 14px;
  border-radius: 0.2rem;
  color: var(--black-color);
  background: rgb(204, 202, 202);
}

/* .vs__selected {
  background: var(--second-color);
}

.vs__dropdown-option--selected {
  background: var(--primary-color);
  color: var(--white-color);
}

.style-chooser2 .vs__search::placeholder,
.style-chooser2 .vs__dropdown-toggle,
.style-chooser2 .vs__dropdown-menu {
  color: var(--black-color);
  font-size: 0.875rem;
}

.style-chooser2 .vs__search::placeholder {
  color: hite;
}

.style-chooser2 .vs__dropdown-toggle {
  height: 38.24px;
  border: 1px solid rgb(107, 107, 107);
}

.style-chooser2 .vs__clear,
.style-chooser2 .vs__open-indicator {
  fill: rgb(107, 107, 107);
}

.style-chooser2 span {
  color: var(--white-color);
}

.vs__selected svg {
  fill: white;
} */
</style>
