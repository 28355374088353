<template>
  <div>
    <b-row class="mt-2">
      <b-col cols="12">
        <h5 class="text-white">Total a liquidar: <strong>{{ formatTotal(totalSales) }}</strong></h5>
      </b-col>
      <b-col cols="12">
        <base-action-table
          :records="records"
          :fields="fields"
          :busy="isBusy"
          :filtering="filtering"
          :module="module"
          :isVisible="records.length == 0"
          @creating="handleCreating"
          @detail="handleDetail"
          @editing="handleEditing"
          @deleting="handleDeleting"
        >
          <template #cell(total_sales)="data">
            {{ formatTotal(data.item.total_sales) }}
          </template>
          <template #cell(total_costs)="data">
            {{ formatTotal(data.item.total_costs) }}
          </template>
          <template #cell(utility)="data">
            <div :class="{ 'text-danger': isUtilityNegative }">
              {{ isUtilityNegative ? "-" : "" }}
              {{ formatUtility(data.item.utility) }}
            </div>
          </template>
        </base-action-table>
      </b-col>
    </b-row>
    <base-modal :title="title" :id="id">
      <template v-slot:form>
        <form-liquidation
          :isDisabled="isDisabled"
          :record="record"
          :totalSales="totalSales"
          :travelUuid="travelUuid"
          @save="update"
        />
      </template>
    </base-modal>
  </div>
</template>

<script>
import ActionCRUD from "@/mixins/ActionCRUD";
//Components
import { BCol, BRow, VBTooltip } from "bootstrap-vue";
import BaseActionTable from "@/components/base/BaseActionTable";
import BaseModal from "@/components/base/BaseModal";
import FormLiquidation from "./FormLiquidation";
import { formatPrice } from "@/utils";

export default {
  inject: ["liquidationRepository"],
  mixins: [ActionCRUD],
  components: {
    BCol,
    BRow,
    BaseActionTable,
    BaseModal,
    FormLiquidation,
  },
  directives: {
    "b-tooltip": VBTooltip,
  },
  props: {
    travelUuid: {
      type: String,
      default: null,
    },
    totalSales: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      record: null,
      records: [],
      fields: [
        {
          key: "total_sales",
          label: "Total ventas",
        },
        {
          key: "total_costs",
          label: "Total gastos",
        },
        {
          key: "utility",
          label: "Utilidad",
        },
        { key: "actions", label: "Accion" },
      ],
      isBusy: false,
      filtering: false,
      isDisabled: false,
      id: "modal-add-liquidation",
      title: "Nueva Liquidación",
      module: "7d815776-cb9a-43d7-954f-f9e6b96d80fa",
      isUtilityNegative: false,
    };
  },
  mounted() {
    const me = this;
    me.loadLiquidation();
  },
  methods: {
    async loadLiquidation() {
      const me = this;
      try {
        me.isBusy = true;
        const { data } = await me.liquidationRepository.getAllByTravel(me.travelUuid);
        me.records = data;
      } catch (error) {
        const { status } = error.data;
        if (status == 419) {
          me.$bvModal.show("expired-token-model");
        } else if (status == 403) {
          me.$bvModal.show("session-finish-modal");
        }
      } finally {
        me.isBusy = false;
      }
    },
    handleCreating() {
      const me = this;
      me.title = "Nueva Liquidación";
      me.isDisabled = false;
      me.record = null;
      if (me.records.length === 0) me.$bvModal.show(me.id);
    },
    handleEditing(item) {
      const me = this;
      me.title = "Actualizar Liquidación";
      me.isDisabled = false;
      me.record = item;
      me.$bvModal.show(me.id);
    },
    handleDetail(item) {
      const me = this;
      me.title = "Detalle Liquidación";
      me.isDisabled = true;
      me.record = item;
      me.$bvModal.show(me.id);
    },
    async handleDeleting(uuid) {
      const me = this
      me.$bvModal.msgBoxConfirm('¿Seguro que deseas eliminar este registro?', {
        title: 'Confirmar eliminación',
        size: 'sm',
        buttonSize: 'sm',
        okVariant: 'danger',
        okTitle: 'Si',
        cancelTitle: 'No',
        footerClass: 'p-2',
        hideHeaderClose: false,
        centered: true
      })
        .then(async (value) => {
          if (value) {
            try {
              await me.liquidationRepository.delete(uuid)

              me.messageSuccess('Registro eliminado', 'Registro eliminado con exito');

              me.loadLiquidation()
            } catch (error) {
              const { message, status } = error.data;
              if (status == 419) {
                me.$bvModal.show("expired-token-model");
              } else if (status == 403) {
                me.$bvModal.show("session-finish-modal");
              }
              me.errorSave(message)
            }
            
          }
        })
    },
    update() {
      const me = this;
      me.messageSuccess();
      me.loadLiquidation();
      me.$bvModal.hide(me.id);
    },
    formatTotal(value) {
      return formatPrice(value + "");
    },
    formatUtility(value) {
      const me = this;
      me.isUtilityNegative =
        Math.sign(value) === 1 || Math.sign(value) === 0 ? false : true;

      if (me.isUtilityNegative) {
        return formatPrice(value + "");
      }

      return formatPrice(Math.abs(value) + "");
    },
  },
};
</script>
